<template>
  <v-container class="checkout">
    <validation-observer ref="observer" v-slot="{ invalid }">
      <form @submit.prevent="submit">
        <v-row no-gutters>
          <!-- SELECT PAYMENT METHOD -->
          <v-col cols="12" md="8">
            <h3 class="mt-12 mb-6">{{ $t("checkout.PAYMENTMETHODS") }}</h3>
            <hr class="summary-line summary-line-select" />

            <!-- PAYMENT METHOD IMAGES -->

            <v-row no-gutters>
              <v-col
                cols="12"
                sm="3"
                md="3"
                class="mb-4 text-center"
                @click="checkSelectedPaymentMethod('cc')"
              >
                <img
                  src="../../../../../assets/images/visa.png"
                  class="checkout__payment-image"
                  :class="{
                    'payment-active': isUserSelectePaymentMethod == 'cc',
                  }"
                  alt
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="4"
                class="mb-4 text-center"
                @click="checkSelectedPaymentMethod('fawry')"
              >
                <img
                  src="../../../../../assets/images/fawry.png"
                  class="checkout__payment-image"
                  :class="{
                    'payment-active': isUserSelectePaymentMethod == 'fawry',
                  }"
                  alt
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="4"
                class="mb-4 text-center"
                @click="checkSelectedPaymentMethod('code')"
              >
                <img
                  src="../../../../../assets/images/code.jpg"
                  class="checkout__payment-image"
                  :class="{
                    'payment-active': isUserSelectePaymentMethod == 'code',
                  }"
                  alt
                />
              </v-col>
            </v-row>

            <v-card max-width="600" elevation="0" class="my-12">
              <v-card-text>
                <Alert />
                <label class="font-weight-bold black--text">{{
                  $t("checkout.SelectPAYMENTMETHODS")
                }}</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="paymentMethod"
                  rules="required"
                >
                  <v-select
                    v-model="payment_method"
                    :error-messages="errors"
                    :items="items"
                    item-text="name"
                    item-value="value"
                    :label="$t('validations.fields.paymentMethod')"
                    type="text"
                    required
                    single-line
                    outlined
                    height="50"
                  ></v-select>
                </validation-provider>
                <div v-if="payment_method === 'fawry'" class="fawry-checkbox">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Checkbox"
                    :rules="{ required: { allowFalse: false } }"
                  >
                    <v-list-item three-line>
                      <v-list-item-action>
                        <v-checkbox
                          v-model="checkbox"
                          :error-messages="errors"
                          required
                          value="checkbox"
                          hide-details
                        ></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("checkout.Guide Info")
                        }}</v-list-item-title>
                        <v-list-item-subtitle>
                          {{ $t("checkout.firstStep") }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{ $t("checkout.secondStep") }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{ $t("checkout.thirdStep") }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{ $t("checkout.fourthStep") }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </validation-provider>
                </div>
                <label class="font-weight-bold black--text">{{
                  $t("validations.fields.phoneNumber")
                }}</label>
                <validation-provider
                  v-slot="{ errors }"
                  name="phoneNumber"
                  :rules="{ required: true, mobileregex: true, numeric: true }"
                >
                  <v-text-field
                    v-model="mobile"
                    :error-messages="errors"
                    :label="$t('validations.fields.phoneNumber')"
                    type="text"
                    required
                    single-line
                    outlined
                    height="50"
                  ></v-text-field>
                </validation-provider>
                <div v-if="payment_method === 'cc'">
                  <label class="font-weight-bold black--text">{{
                    $t("validations.fields.cardNumber")
                  }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="cardNumber"
                    :rules="{ required: true, numeric: true, digits: 16 }"
                  >
                    <v-text-field
                      v-model="ccNum"
                      :error-messages="errors"
                      :label="$t('validations.fields.cardNumber')"
                      type="text"
                      required
                      single-line
                      outlined
                      height="50"
                    ></v-text-field>
                  </validation-provider>
                  <v-container>
                    <v-row>
                      <v-col md="4" cols="4" class="text-center">
                        <label class="font-weight-bold black--text">{{
                          $t("validations.fields.month")
                        }}</label>
                        <validation-provider
                          v-slot="{ errors }"
                          name="month"
                          rules="required"
                        >
                          <v-select
                            v-model="ccExpMonth"
                            :error-messages="errors"
                            :items="months"
                            label="MM"
                            type="text"
                            required
                            single-line
                            outlined
                            height="50"
                          ></v-select>
                        </validation-provider>
                      </v-col>
                      <v-col md="4" cols="4" class="text-center">
                        <label class="font-weight-bold black--text">{{
                          $t("validations.fields.year")
                        }}</label>
                        <validation-provider
                          v-slot="{ errors }"
                          name="year"
                          rules="required"
                        >
                          <v-select
                            v-model="ccExpYear"
                            :error-messages="errors"
                            :items="years"
                            label="YY"
                            type="text"
                            required
                            single-line
                            outlined
                            height="50"
                          ></v-select>
                        </validation-provider>
                      </v-col>
                      <v-col md="4" cols="4" class="text-center">
                        <label class="font-weight-bold black--text">CVV</label>
                        <validation-provider
                          v-slot="{ errors }"
                          name="cardCode"
                          :rules="{ required: true, numeric: true, digits: 3 }"
                        >
                          <v-text-field
                            v-model="cvvNum"
                            :error-messages="errors"
                            label="CVV"
                            type="text"
                            required
                            single-line
                            outlined
                            height="50"
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
                <div v-if="payment_method === 'code'">
                  <label class="font-weight-bold black--text">{{
                    $t("validations.fields.codeNumber")
                  }}</label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="codeNumber"
                    rules="required"
                  >
                    <v-text-field
                      v-model="code"
                      :error-messages="errors"
                      :label="$t('validations.fields.codeNumber')"
                      type="text"
                      required
                      single-line
                      outlined
                      height="50"
                    ></v-text-field>
                  </validation-provider>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- ORDER SUMMARY -->
          <v-col cols="12" md="4">
            <div class="checkout__summary text-center px-7">
              <img
                src="../../../../../assets/images/checkout-summary.png"
                class="checkout__summary-image"
                alt
              />
              <div class="text-center">
                <h3>{{ $t("checkout.OrderSummary") }}</h3>
                <hr class="summary-line" />
              </div>

              <div class="row justify-content-between">
                <div class="col-6">
                  <p class="font-weight-bold">{{ lectures.name }}</p>
                </div>
                <div class="col-6">
                  <p class="font-weight-bold">{{ lectures.price }} L.E</p>
                </div>
              </div>
              <div>{{ lectures.description }}</div>
              <div class="my-5 mt-13 pa-3 checkout-date" outlined tile>
                {{ currentDate }}
              </div>

              <v-btn
                rounded
                x-large
                block
                :loading="loading"
                type="submit"
                :disabled="invalid"
                color="primary"
                class="my-12 px-12"
                >{{ $t("checkout.BuyNow!") }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </form>
    </validation-observer>
  </v-container>
</template>


<script>
import lecturesModule from "@/store/modules/lectures";
import profileModule from "@/store/modules/profile";
import Alert from "../../../../../components/alert/alert.vue";
export default {
  data: () => ({
    items: [
      { name: "Credit Card", value: "cc" },
      { name: "Fawry", value: "fawry" },
      { name: "Code", value: "code" },
    ],
    months: [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ],
    years: ["21", "22", "23", "24", "25", "26", "27", "28", "29", "30"],

    lecture_id: null,
    payment_method: null,
    mobile: null,
    ccNum: null,
    cvvNum: null,
    ccExpMonth: null,
    ccExpYear: null,
    code: null,
    loading: false,
    checkbox: false,
    isUserSelectePaymentMethod: "",
  }),
  components: {
    Alert,
  },
  methods: {
    getLecturesById(id) {
      this.loader = "loading";
      this.$store
        .dispatch("lectures/getLecturesById", id)
        .then(() => {
          this.loader = "success";
        })
        .catch(() => {
          this.loader = "error";
        });
    },
    redirectToLecture() {
      let lectureId = localStorage.getItem("7asety_lectureId");
  
       this.$router.push(
        "/lecture/" + lectureId
      );

      // localStorage.removeItem("7asety_lectureId");

    },
    getProfile() {
      this.$store
        .dispatch("profile/getProfile")
        .then((res) => {
          this.mobile = res.mobile;
        })
        .catch(() => {
          this.loader = "error";
        });
    },
    submit() {
      // this.$refs.observer.validate();
      this.loading = true;
      const payment = {
        lecture_id: localStorage.getItem("7asety_lectureId"),
        payment_method: this.payment_method,
        mobile: this.mobile,
        ccNum: this.ccNum,
        cvvNum: this.cvvNum,
        ccExpMonth: this.ccExpMonth,
        ccExpYear: this.ccExpYear,
        code: this.code,
      };

      this.$store
        .dispatch("lectures/payment", payment)
        .then((res) => {
          this.loading = false;

          if (this.payment_method == "code") {
            this.redirectToLecture();
          }
          // credit case
          if (res.three_d_secured == false && res.transaction != null) {
            // this.$router.push("/success-payment");
          } else if (res.three_d_secured == true && res.transaction == null) {
            COWPAYOTPDIALOG.init();
            COWPAYOTPDIALOG.load(res.reference_id);
          }
        })
        .catch((err) => {
          if (err) {
            this.invalidData = err.errors;

            this.loading = false;
          }
        });
    },

    checkSelectedPaymentMethod(active) {
      this.isUserSelectePaymentMethod = active;
      this.payment_method = active;
    },
    cowpayCallBackHandeler: function (e) {
        if (e.data && e.data.message_source === 'cowpay') {
            // console.log("paid");

          if(e.data.payment_status == "PAID"){
            this.redirectToLecture();

          }
        }
    
    }
  },
  computed: {
    lectures() {
      return this.$store.state.lectures.lectures;
    },
    currentDate() {
      return new Date().toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    profileInfo() {
      return this.$store.state.profile.profileInfo;
    },
  },
  mounted() {
    //listen to cowpay callback
    window.addEventListener('message', this.cowpayCallBackHandeler );
    //get user profile
    this.getProfile();
    //add cowpay script
    let externalScript = document.createElement('script')
    externalScript.setAttribute('src',  process.env.VUE_APP_COWPAY_URL)
    document.head.appendChild(externalScript)
  },
  created() {
    if (!lecturesModule.isRegistered) {
      this.$store.registerModule("lectures", lecturesModule);
      lecturesModule.isRegistered = true;
    }
    if (localStorage.getItem("7asety_lectureId")) {
      this.getLecturesById(localStorage.getItem("7asety_lectureId"));
    }
    if (!profileModule.isRegistered) {
      this.$store.registerModule("profile", profileModule);
      profileModule.isRegistered = true;
    }
  },
};
</script>
<style scoped>
.fawry-checkbox {
  margin-top: -25px;
}
</style>