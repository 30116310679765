<template>
    <div>
        <v-alert v-show="show"
                 dense outlined type="error">{{error}}</v-alert>
    </div>
</template>

<script>
  export default {
     created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "SHOW_ERROR") {
        this.error = state.Alert.error;
        this.show = true;
      }
    });
  },
     data() {
    return {
      show: false,
      error: ""
    };
  },
    }
</script>