
export default {
 
  SET_GRADES(state, grades) {
    state.grades=grades
  },

  SET_HOME(state, home) {
    state.home=home
  },

  SET_SEARCH(state, search) {
    state.search=search
  },

}
