<template>
    <v-container>
        <v-row no-gutters>
      <v-col
        md="6"
        offset-md="3"
      >
        <div
          outlined
          tile
        >
        <div class="text-center mt-12">
          <img src="../../../../../assets/images/failed 241@3x.png" class="payment__image" alt />
        </div>
        <h2 class="payment__title">{{ $t('failedPayment.FAILEDPAYMENT') }}</h2>
        <div class="text-center">
    <v-btn
      rounded
      x-large
      color="primary"
      dark
      class="my-12 px-12"
      @click="tryAgain"
    >
      {{ $t('failedPayment.Pleasetryagain') }}
    </v-btn>
  </div>
        
        </div>
      </v-col>
    </v-row>
    </v-container>
  
</template>
<script>

export default {
  data: () => ({
  }),
  methods: {
    tryAgain() {
     if(localStorage.getItem("7asety_lectureId")){
      
        this.$router.push("/lecture/" + localStorage.getItem("7asety_lectureId"));

     }else{
       
        this.$router.push("/courses");

     }
    },
 
  },

};
</script>
