<template>
  <v-container fill-height="fill-height">
    <v-layout align-center="align-center" justify-center="justify-center">
      <v-flex class="login-form text-xs-center">
        <v-card light="light" max-width="500" elevation="0" class="mx-auto " :class="{'my-12':$vuetify.breakpoint.mdAndUp}">
      
          <v-card-text>
                  <v-alert v-if="invalidData" dense outlined type="error">{{invalidData}}</v-alert>

            <validation-observer ref="observer" v-slot="{ invalid }">
              <form @submit.prevent="submit">
                 <validation-provider
                          v-slot="{ errors }"
                          name="password"
                          rules="required|min:8|passwordRegex"
                        >
                          <v-text-field
                            v-model="forgotpass.password"
                            :type="show1 ? 'text' : 'password'"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show1 = !show1"
                            :error-messages="errors"
                            :label= "$t('validations.fields.password')"
                            required
                            single-line
                            outlined
                            height="50"
                          ></v-text-field>
                        </validation-provider>
                        <validation-provider
                          v-slot="{ errors }"
                          name="ConfirmPassword"
                          rules="required|confirmed:password|min:8"
                        >
                          <v-text-field
                            v-model="forgotpass.password_confirmation"
                            :type="show2 ? 'text' : 'password'"
                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show2 = !show2"
                            :error-messages="errors"
                            :label= "$t('validations.fields.ConfirmPassword')" 
                            required
                            single-line
                            outlined
                            height="50"
                          ></v-text-field>
                        </validation-provider>
              
           

                <v-btn
                large
                  block
                  :loading="loading"
                  color="primary"
                  
                  type="submit"
                  :disabled="invalid"
                >{{$t('validations.fields.Submit')}}</v-btn>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>




export default {
 
  data: () => ({
      forgotpass:{
          password:'',
          password_confirmation:'',
          token:'',
          email: localStorage.getItem('7asety_email')
      },
    
    invalidData: false,

    loading: false,
    show1: false,
    show2: false
  }),

   
  mounted(){
    this.forgotpass.token = this.$route.query.token;
    if(this.$route.query.email){
    this.forgotpass.email = this.$route.query.email;

    }
  },
  methods: {
    submit() {
      // this.$refs.observer.validate();
      this.loading=true
    
      this.$store
        .dispatch("auth/resetPassword",this.forgotpass)
        .then((res) => {
          this.invalidData=false
          this.loading=false
   
          this.$router.push("/");

        })
        .catch((err) => {
          if (err.errors&&err.errors.email) {
            this.invalidData = err.errors.email;
            this.loading=false
          }else{
          // this.invalidData=err.message

          }

        });
    },
    
  },
};
</script>

<style>
.auth-title{
  font-size: 25px !important;
    font-weight: bold !important;
}
</style>