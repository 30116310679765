import endpoints from "../common/endpoints";
import http from "../common/http";
const signinApiUrl = endpoints.SIGN_IN_ENDPOINT;
const signupApiUrl = endpoints.SIGN_UP_ENDPOINT;
const forgetPasswordApiUrl = endpoints.FORGET_PASSWORD_ENDPOINT;
const resetPasswordApiUrl = endpoints.RESET_PASSWORD_ENDPOINT;
const resendEmailApiUrl = endpoints.RESEND_EMAIL_ENDPOINT;
const checkEmailApiUrl = endpoints.CHECK_EMAIL_ENDPOINT;

export default{
    login(email,password) {
        return http.post(signinApiUrl,{email,password}).then((res)=>{
            return res
        })
    },
    signup(data) {
        return http.post(signupApiUrl,data)
    },
    forgetPassword(data) {
        return http.post(forgetPasswordApiUrl,data)
    },
    resetPassword(data) {
        return http.post(resetPasswordApiUrl,data)
    },
    resendEmail(data) {
        return http.post(resendEmailApiUrl,data)
    },
    checkEmail(data) {
        return http.post(checkEmailApiUrl,data)
    }
}
