
import moment from "moment";
import Vue from "vue"

Vue.filter("formateTime", value => {  
      if (value) {
        return moment(value).startOf('seconds').fromNow();
       }
})

