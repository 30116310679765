<template>
  <div
   v-if="!$route.meta.customBreadcrumb"
    class="breadcrumb"
    :class="$route.meta.customBreadcrumb?'breadcrumb__custom':'breadcrumb__auth'"
    :style="{'height':$route.meta.breadcrumbHeight}"
  >
    <v-container>
      <h3 v-if="!$route.meta.customBreadcrumb">{{$t($route.meta.breadcrumbTitle)}}</h3>
      <v-breadcrumbs :items="$route.meta.breadcrumb">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.to" :disabled="item.disabled">{{ $t(item.text) }}</v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-container>
  </div>
</template>

<script>
export default {
 
  data: () => ({}),
};
</script>

<style>
</style>