import courses from "../../../services/courses";



export default {
  getGrades({ commit }) {
    return new Promise((resolve, reject) => {
      courses
        .getGrades()
        .then((resp) => {
            commit("SET_GRADES",  resp.response.data);
            resolve( resp.response.data);
          
        })
        .catch((resp) => {
       
          return reject(resp);
        });
    });
  },
  getCourses({ commit },params) {
    return new Promise((resolve, reject) => {
      courses.getCourses(params)
        .then((resp) => {
            commit("SET_COURSES",  resp.response);
            resolve( resp.response.data);
          
        })
        .catch((resp) => {
       
          return reject(resp);
        });
    });
  },
  getCourseByID({ commit },id) {
    return new Promise((resolve, reject) => {
      courses.getCourseByID(id)
        .then((resp) => {
            commit("SET_SINGLE_COURSES",  resp.response.data);
            resolve( resp.response.data);
          
        })
        .catch((resp) => {
       
          return reject(resp);
        });
    });
  },
  getSubjects({ commit }) {
    return new Promise((resolve, reject) => {
      courses
        .getSubjects()
        .then((resp) => {
            commit("SET_SUBJECTS",  resp.response.data);
            resolve( resp.response.data);
          
        })
        .catch((resp) => {
       
          return reject(resp);
        });
    });
  },
   getTeachers({ commit }) {
    return new Promise((resolve, reject) => {
      courses
        . getTeachers()
        .then((resp) => {
            commit("SET_TEACHERS",  resp.response.data);
            resolve( resp.response.data);
          
        })
        .catch((resp) => {
       
          return reject(resp);
        });
    });
  },
 
};
