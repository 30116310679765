export default {
  reviews: [],
  lectures: {
    ar : {
      name: '',
      description: ''
    },
    en : {
      name: '',
      description: ''
    }
  },

  lecture: {
   ar : {
     name: '',
     description: ''
   },
   en : {
     name: '',
     description: ''
   }
 },
  
  }