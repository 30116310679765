import endpoints from "../common/endpoints";
import http from "../common/http";

const contactusUrl = endpoints.CONTACTUS_ENDPOINT;
const homeUrl = endpoints.HOME_ENDPOINT;
const searchUrl = endpoints.GENERAL_SEARCH_ENDPOINT;

export default{
  
    postContactus(data) {
        return http.post(contactusUrl, data).then((res)=>{
            return res
        })
    },
    getHome() {
        return http.get(homeUrl).then((res)=>{
            return res
        })
    },
    generalSearch(search) {
        return http.get(searchUrl, {
            params: {
                search: search,
            }, 
          }).then((res)=>{
            return res
        })
    },


}
