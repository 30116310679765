
<template>
  <div class="joinus">
    <v-container>
      <v-row>
        <v-col>
          <v-card height="300" class="py-12 px-7" color="primary">
            <h3>{{$t('home.JoinAndBecome.BecomeInstructor')}}</h3>
            <p>
              {{$t('home.JoinAndBecome.InstructorDes')}}
            </p>
            <v-btn to="/teacher/register" large class="white primary--text">
              {{$t('Buttons.GetInTouch')}}
              <v-icon right dark>mdi-arrow-right</v-icon>
            </v-btn>
          </v-card>
        </v-col>
        <v-col>
          <v-card height="300" class="py-12 px-7" color="evergreen">
            <h3>{{$t('home.JoinAndBecome.JoinAccess')}}</h3>
            <p>
              {{$t('home.JoinAndBecome.Getunlimited')}}
            </p>
            <v-btn to="/courses"  large class="white evergreen--text">
              {{$t('Buttons.GetStarted')}}
              <v-icon right dark>mdi-arrow-right</v-icon>
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>