<template>
  <v-container fill-height="fill-height">
    <v-layout align-center="align-center" justify-center="justify-center">
      <v-flex class="login-form text-xs-center">
        <v-card light="light" max-width="700" elevation="0" class="mx-auto">
        
          <v-card-text>
            <validation-observer ref="observer" v-slot="{ invalid }"> 
              <form @submit.prevent="submit">
             
                <validation-provider v-slot="{ errors }" name="password" rules="required|min:8|passwordRegex">
                  <v-text-field
                    v-model="current_password"
                    :type="show1 ? 'text' : 'password'"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show1 = !show1"
                    :error-messages="errors"
                    :label="$t('validations.fields.password')"
                    required
                    single-line
                    outlined
                    height="50"
                  ></v-text-field>
                </validation-provider>
                <validation-provider v-slot="{ errors }" name="NewPassword" rules="required|min:8|passwordRegex">
                  <v-text-field
                    v-model="password"
                    :type="show2 ? 'text' : 'password'"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show2 = !show2"
                    :error-messages="errors"
                    :label="$t('validations.fields.NewPassword')"
                    required
                    single-line
                    outlined
                    height="50"
                  ></v-text-field>
                </validation-provider>
                <validation-provider v-slot="{ errors }" name="ConfirmPassword" rules="required|min:8|passwordRegex">
                  <v-text-field
                    v-model="c_password"
                    :type="show3 ? 'text' : 'password'"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show3 = !show3"
                    :error-messages="errors"
                    :label="$t('validations.fields.ConfirmPassword')"
                    required
                    single-line
                    outlined
                    height="50"
                  ></v-text-field>
                </validation-provider>

                <v-btn
                  large
                  block
                  :loading="loading"
                  color="primary"
                  
                  type="submit"
                  :disabled="invalid"
                >{{$t('validations.fields.Submit')}}</v-btn>
              </form>
             
              
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import profileModule from "@/store/modules/profile";

export default {
 
  data: () => ({
    current_password: "",
    password: "",
    c_password: "",

    invalidData: false,
    loading: false,
    show1: false,
    show2: false,
    show3: false,
  }),

   
  mounted() {
  },
  methods: {
    submit() {
      // this.$refs.observer.validate();
      const updatePassword = {
        current_password : this.current_password,
        password : this.password,
        c_password : this.c_password
      };
      this.loading = true;
   
      this.$store
        .dispatch("profile/updatePassword", updatePassword)
        .then((res) => {
          this.loading = false;
          this.current_password = res.current_password
       
        })
        .catch((err) => {
          if (err) {
            this.invalidData = err.errors;
            
            this.loading = false;
          }
        });
    },
  
    
  },

   created() {
         if (!profileModule.isRegistered) {
      this.$store.registerModule("profile", profileModule);
      profileModule.isRegistered = true;
    }
  
  },
};
</script>

<style>
.auth-title {
  font-size: 25px !important;
  font-weight: bold !important;
}
</style>