<template>
 <v-container >
      <v-simple-table class="mb-3 mx-auto payment-table" >
    <template v-slot:default>
       <div class="loader" v-if="loader=='loadingpayments' ||loader=='nopayments'||loader=='error'">
         
            
       
            <v-list-item-subtitle
              v-if="loader=='nopayments'"
              class=" text-center mb-12 mt-md-12 mt-sm-2"
            >
            <h3>{{$t('payments.YouDontHaveAnySavedPayments')}}</h3>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="loader=='error'"
              class="danger--text text-center"
            >{{$t('payments.ErrorOccured')}}</v-list-item-subtitle>
          </div>
      <thead v-show="loader=='success' ">
        <tr>
          <th>
            <h3 class="font-weight-black">{{$t('payments.Lecture')}}/ {{$t('payments.Exam')}}</h3>
          </th>
          <th>
            <h3 class="font-weight-black">{{$t('payments.Date')}}</h3>
          </th>
           <th>
            <h3 class="font-weight-black">{{$t('payments.Price')}}</h3>
          </th>
          <th>
            <h3 class="font-weight-black">{{$t('payments.Method')}}</h3>
          </th>
          <th>
            <h3 class="font-weight-black">{{$t('payments.Status')}}</h3>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-show="loader=='success' "
            :key="index+'payment'"
            v-for="(payment, index) of payments" 
        >
        
          <td > 
            <router-link :to="`/lecture/${payment.id}`">
                {{ payment.lecture }}  
            </router-link>
          </td>
          <td>{{ payment.date| formatDateTime('M-D-Y, h:mm a')}}</td>
          <td>{{ payment.price }}</td>
          <td>{{ payment.method }}</td>
          <td>{{ payment.status }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
     <v-row :class="{'my-12':$vuetify.breakpoint.mdAndUp}">
        <v-col   v-if="paymentsMeta.last_page >1" cols="12" order="last">
          <v-pagination
            class="my-5"
            :length="paymentsMeta.last_page"
            v-model="page"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
 
  </v-container>
</template>

<script>

import profileModule from "@/store/modules/profile";
import moment from 'moment';

  export default {
     data: () => ({
    page: 1,
    method: null,
    status: null,
    lecture: null,
    price: null,
    date: null,
    loader: null,
  }),
  mounted(){
    this.getPayments()

  },
   methods: {
      onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      // this.fab = top > 20;
    },
    getPayments() {
     
      this.$store
        .dispatch("profile/getPayments")
        .then((res) => {
       

          if (res.length) {
            this.loader = "success";
          } else {

            this.loader = "nopayments";
          }
        })
        .catch(() => {
          this.loader = "error";
        });
    },
  
  },
  watch: {
  
    page(obj) {
      this.page = +obj || 1;
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          page: this.page,
        }),
      });
    },
  
  },

  computed: {
    payments() {
      return this.$store.state.profile.payments;
    },
     paymentsMeta() {
      return this.$store.state.profile.paymentsMeta;
    },
  
  },
  created() {
         if (!profileModule.isRegistered) {
      this.$store.registerModule("profile", profileModule);
      profileModule.isRegistered = true;
    }
   
  
  },

     
  }
</script>

