const alert = {
    state: {
        error: "",
    },
    mutations: {
        SHOW_ERROR(state, payload) {
        state.error = payload.error;
      },
    },
    actions: {
      showAlert({ commit }, payload) {
        commit("SHOW_ERROR", payload);
      },
    },
    getters: {},
  };
  export default alert;