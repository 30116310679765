<template>
  <v-container fill-height="fill-height">
    <v-layout align-center="align-center" justify-center="justify-center">
      <v-flex class="login-form text-xs-center">
        <v-card light="light" max-width="500" elevation="0" class="mx-auto " :class="{'my-12':$vuetify.breakpoint.mdAndUp}">
          <v-img
            height="300"
            width="300"
            class="mx-auto"
            src="../../../assets/images/emaillconfirm.png"
            aspect-ratio="1.7"
          ></v-img>

          <v-card-text class="my-5 py-5">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo</v-card-text>

          <v-btn large block color="primary" class="mt-5" to="/home">Back To Home</v-btn>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>