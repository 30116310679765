<template>
<v-container >
      <v-simple-table class="px-sm-7 px-md-0 mx-auto mx-md-0">
    <template v-slot:default>
       <div class="loader" v-if="loader=='loadingsubscriptions' ||loader=='nosubscriptions'||loader=='error'">
            <v-list-item-subtitle
              v-if="loader=='nosubscriptions'"
              class=" text-center mb-12 mt-md-12 mt-sm-2"
            >
            <h3>{{$t('subscriptions.YouDontHaveAnySavedSubscriptions')}}</h3>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="loader=='error'"
              class="danger--text text-center"
            >{{$t('payments.ErrorOccured')}}</v-list-item-subtitle>
          </div>
      <thead v-show="loader=='success' ">
        <tr>
         <th>
            <h3 class="font-weight-black">{{$t('subscriptions.Lecture')}}/ {{$t('subscriptions.Exam')}}</h3>
          </th>
          <th> 
            <h3 class="font-weight-black">{{$t('subscriptions.EnrollmentDate')}}</h3>
          </th>
           <th>
            <h3 class="font-weight-black">{{$t('subscriptions.ExpiryDate')}}</h3>
          </th>
          <th>
            <h3 class="font-weight-black">{{$t('subscriptions.Status')}}</h3>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-show="loader=='success' "
            :key="index+'subscription'"
            v-for="(subscription, index) of subscriptions"
        >
          <td>
            <router-link :to="`/lecture/${subscription.id}`">
            <v-row>
              <v-img 
              max-height="30"
              max-width="30" 
              :src="subscription.lecture.image"
              ></v-img>
              <p class="mx-2">{{ subscription.lecture.name }}</p>
            </v-row>
            </router-link>
          </td>
          <td>{{ subscription.enrollment_date }}</td>
          <td>{{ subscription.expire_date }}</td>
          <td>{{ subscription.status }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
 
  </v-container>

</template>

<script>

import profileModule from "@/store/modules/profile";

  export default {
     data: () => ({
    id: null,
    status: null,
    enrollment_date: null,
    expire_date: null,
    lecture_id: null,
    loader: null,  
  }),
   methods: {
          onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      // this.fab = top > 20;
    },
    getSubscriptions() {
  this.$store
        .dispatch("profile/getSubscriptions", "active")
        .then((res) => {
      
          if (res.length) {
            this.loader = "success";
          } else {

            this.loader = "nosubscriptions";
          }

        })
        .catch(() => {
          this.loader = "error";

        });
    },
  
  },

  computed: {
    subscriptions() {
      return this.$store.state.profile.subscriptions; 
    },
  
  },
  created() {
         if (!profileModule.isRegistered) {
      this.$store.registerModule("profile", profileModule);
      profileModule.isRegistered = true;
    }
   
    if (this.$route.query) {
      this.getSubscriptions(this.$route.query);
    }
  },
  }
</script>