<template>
  <div>
      <Breadcrumb/>
      <router-view/>
  </div>
</template>

<script>
import Breadcrumb from "../../components/breadcrumb/Breadcrumb.vue";

export default {
  components:{
    Breadcrumb
  }

}
</script>

<style>

</style>