import endpoints from "../common/endpoints";
import http from "../common/http";
const examUrl = endpoints.EXAM_ENDPOINT;

export default {
  getExam(exam) {
    return http.get(`${examUrl}/content/${exam}`).then((res) => {
      return res;
    });
  },
  createExam(id,type) {
    return http.post(`${examUrl}/${type}/${id}`).then((res) => {
      return res;
    });
  },
  saveAnswer(data){
      return http.post(`${examUrl}/save/content/${data.id}`,data.body).then((res) => {
      return res;
    });
  },
  submitExam(data){
      return http.post(`${examUrl}/submit/content/${data.id}`,data.body).then((res) => {
      return res;
    });
  }
  
};
