export default {
  exams: [
    // {
    //   ending_at: "2021-08-12T18:24:44.000000Z",
    //   exam_minutes: 10,
    //   finalScore: 1,
    //   id: 2,
    //   started_at: "2021-08-12T18:14:44.000000Z",
    //   questions: [
    //     {
    //       answers: "",
    //       id: 8,
    //       image: "null",
    //       question: "<p>fdfgdfhfdh?</p>",
    //       score: 1,
    //       type: "choose",
    //     },
    //   ],
    // },
  ],
  showExams: [
    // {
    //   "id": 1,
    //   "question": "question 1",
    //   "answers": [
    //     {
    //       "id": 1,
    //       "answer": 2
    //     }
    //   ],
    //   "score": 50,
    //   "type": "choose",
    //   "image": "https://7sety.com/storage/1/7sety.png"
    // }
  ],
};
