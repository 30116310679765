<template>
  <v-container fill-height="fill-height">
    <v-layout align-center="align-center" justify-center="justify-center">
      <v-flex class="login-form text-xs-center">
        <v-card light="light" max-width="500" elevation="0" :class="{'my-12':$vuetify.breakpoint.mdAndUp}" class="mx-auto ">
          <v-list>
            <v-list-item class="text-center">
              <v-list-item-content>
                <h1 class="auth-title">{{$t('contactus.title')}}</h1>
                
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-text>
            <validation-observer ref="observer" v-slot="{ invalid }">
              <form ref="afterSubmit" @submit.prevent="submit">
                   <validation-provider
                          v-slot="{ errors }"
                          name="name"
                          :rules="{required:true,min:3,max:30,alpha_spaces:true
}"
                        >
                          <v-text-field
                            v-model="name"
                            :error-messages="errors"
                            :label= "$t('validations.fields.name')"
                            type="text"
                            required
                            single-line
                            outlined
                            height="50"
                          ></v-text-field>
                        </validation-provider>
                <validation-provider v-slot="{ errors }" name="email" rules="required|email">
                  <v-alert v-show="invalidData.length"
                  :key="index+'dfsdf'"
                  v-for="(err, index) of invalidData" dense outlined type="error">{{err}}</v-alert>
                
                  <v-text-field
                    v-model="email"
                    :error-messages="errors"
                    :label= "$t('validations.fields.email')"
                    type="email"
                    required
                    single-line
                    outlined
                    height="50"
                  ></v-text-field>
                </validation-provider>
                 <validation-provider
                          v-slot="{ errors }"
                          name="phoneNumber"
                          :rules="{required:true,
                                   numeric:true,
                                   mobileregex:true       
                         }"
                        >
                          <v-text-field
                            v-model="phone"
                            :error-messages="errors"
                            :label= "$t('validations.fields.phoneNumber')"
                            type="text"
                            required
                            single-line
                            outlined
                            height="50"
                          ></v-text-field>
                 </validation-provider>
                   <validation-provider
                          v-slot="{ errors }"
                          name="subject"
                          :rules="{required:true,max:40,min:2
}"
                        >
                          <v-text-field
                            v-model="subject"
                            :error-messages="errors"
                            :label="$t('validations.fields.subject')"
                            type="text"
                            required
                            single-line
                            outlined
                            height="50"
                          ></v-text-field>
                   </validation-provider>
                   <validation-provider
                          v-slot="{ errors }"
                          name="message"
                          :rules="{required:true, max:200
}"
                        >
                          <v-textarea
                            v-model="message"
                            :error-messages="errors"
                            :label="$t('validations.fields.message')"
                            type="text"
                            required
                            single-line
                            outlined
                            height="150"
                          ></v-textarea>
                   </validation-provider>
                <v-btn
                  large
                  block
                  :loading="loading" 
                  color="primary"
                  
                  type="submit"
                  :disabled="invalid"
                >{{$t('validations.fields.Submit')}}</v-btn>
              </form>
              
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import globalModule from "@/store/modules/global";


export default {
 
  data: () => ({
    
      name: "",
      email: "",
      phone: "",
     subject: "",
    message: "",
    invalidData: false,
    loading: false,
    submitting: false
  }),

  computed: {
   
  },
  mounted() {
  
  },
  methods: {
    submit() {
      // this.$refs.observer.validate();
      this.loading = true;
      this.submitting = true;
    const contact ={
      name: this.name,
      email: this.email,
      phone: this.phone,
      subject: this.subject,
      message: this.message,
    };
      this.$store
        .dispatch("global/postContactus", contact)
        .then((res) => {
          this.loading = false;
          this.submitting = false;
         
          // this.$refs.afterSubmit.reset();
          this.name= "";
         this.email= "";
         this.phone= "";
         this.subject= "";
         this.message= "";
         this.$refs.observer.reset();
        })
        .catch((err) => {
          if (err) {
            this.invalidData = err.errors;
            this.loading = false;
          }
        });
    },
  
  },
    created() {
    if (!globalModule.isRegistered) {
      this.$store.registerModule("global", globalModule);
      globalModule.isRegistered = true;
    }
    
  },
};
</script>

<style>
.auth-title {
  font-size: 25px !important;
  font-weight: bold !important;
}
</style>
