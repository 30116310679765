import lectures from "../../../services/lectures";

export default {

    getLecturesById({ commit },id) {
      return new Promise((resolve, reject) => {
        lectures
          .getLecturesById(id)
          .then((resp) => {
            
              commit("SET_LECTURES",  resp.response.data);
              resolve( resp.response.data);
            
          })
          .catch((resp) => {
         
            return reject(resp);
          });
      });
    },

    downloadLectuter({ commit }, data) {
      return new Promise((resolve, reject) => {
        lectures
          .downloadLectuter(data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((res) => {
            return reject(res);
          });
      });
    },

    reviews({ commit }, id) {
        return new Promise((resolve, reject) => {
          lectures
            .reviews(id)
            .then((resp) => {
              
                commit("SET_REVIEWS",  resp.response.data);
                resolve( resp.response.data);
              
            })
            .catch((resp) => {
           
              return reject(resp);
            });
        });
      },
      createReview({commit, dispatch }, body) {
        return new Promise((resolve, reject) => {
          lectures
            .createReview(body)
            .then((resp) => {
              dispatch('reviews',body.id)
              
         
                resolve( resp.response.data);
            })
            .catch((resp) => {
           
              return reject(resp);
            });
        });
      },

      payment({ commit },body ) {
        return new Promise((resolve, reject) => {
          lectures
            .payment(body)
            .then((resp) => {
                resolve( resp.response.data);
              
            })
            .catch((resp) => {
           
              return reject(resp);
            });
        });
      },

      enrollment({ commit }, body) {
        return new Promise((resolve, reject) => {
          lectures
            .enrollment(body)
            .then((resp) => {
              resolve(resp.response);
            })
            .catch((res) => {
              return reject(res);
            });
        });
      },
  
   
  };