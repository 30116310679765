export default {
    SET_PROFILE(state, profile) {
      state.profile = profile;
    },
    SET_PASSWORD(state, password) {
        state.password = password;
    },
    SET_PAYMENTS(state, payments) {
      state.payments = payments.data;
      state.paymentsMeta= payments.meta;
    },
    SET_SUBSCRIPTIONS(state, subscriptions) {
      state.subscriptions = subscriptions;
    },
  };