<template>
  <footer class="footer" :class="{'mb-12 pb-12': !$vuetify.breakpoint.mdAndUp}">
    <v-container>
      <v-row :no-gutters="!$vuetify.breakpoint.mdAndDown" class="footer__list">
        <v-col cols="12" sm="6" md="6"  lg="3">
          <ul>
            <li class="footer__sec3" :class="{'mt-12':!$vuetify.breakpoint.mdAndDown}">
              <img v-if="$i18n.locale =='en'"  src="../../assets/images/footer2@3x.png" alt="" height="50">
              <img v-if="$i18n.locale =='ar'" src="../../assets/images/7esty blaCK ARABIC@3x.png" alt="" height="50">
            </li>
          </ul>
        </v-col>
        <v-col cols="12"  sm="6" md="6" lg="3">
          <h4 class="footer__list-title">{{$t('footer.sitemap')}}</h4>
          <ul>
            <li>
              <router-link to="/home">{{$t('header.Home')}}</router-link>
            </li>
            <li>
              <router-link to="/teachers">{{$t('header.Teachers')}}</router-link>
            </li>
            <li>
              <router-link to="/courses">{{$t('header.Courses')}}</router-link>
            </li>
            <li>
              <router-link to="/contactus">{{$t('header.ContactUs')}}</router-link>
            </li>
            <li>
              <router-link to="/teacher/register">{{$t('footer.BecomeaTeacher')}}</router-link>
            </li>
          </ul>
        </v-col>
        <v-col cols="12" sm="6" md="6"  lg="3">
          <h4 class="footer__list-title">{{$t('footer.Policy')}}</h4>
          <ul>
            <li>
              <a href>{{$t('footer.Privacy')}}</a>
            </li>
            <li>
              <a href>{{$t('footer.Terms')}}</a>
            </li>
            <li>
              <a href>{{$t('footer.Policies')}}</a>
            </li>
          </ul>
        </v-col>
        <v-col cols="12" sm="6" md="6"  lg="3">
          <h4 class="footer__list-title">mobile</h4>
          <ul>
            <li>
              <img src="../../assets/images/google play.png">
            </li>
            <li>
              <img src="../../assets/images/app store.png">
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>

    <v-row no-gutters>
      <v-col class="my-0 mx-0"  cols="12">
        <v-card flat tile color="light_gray
        " class="text-center">
          <v-card-text
            class="light_gray warm_gray--text"
          >{{$t('footer.Copyright')}} Hessety © {{ new Date().getFullYear() }}. {{$t('footer.AllRightsReserved')}}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row no-gutters>
      <v-col class="my-0 mx-0"  cols="12">
        <v-card flat tile color="light_gray
        " class="text-center">
          <v-card-text
            class="light_gray warm_gray--text"
          >
          Powered by <a href="https://kioncm.com/" target="b_blank" > KION</a>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </footer>
</template>

<script>
export default {
}
</script>

<style>
</style>