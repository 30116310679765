<template>
  <v-container fill-height="fill-height">
    <v-layout align-center="align-center" justify-center="justify-center">
      <v-flex class="login-form text-xs-center">
        <v-card light="light" max-width="500" elevation="0" class="mx-auto " :class="{'my-12':$vuetify.breakpoint.mdAndUp}">
       
          <v-card-text>
            <validation-observer ref="observer" v-slot="{ invalid }">
              <form @submit.prevent="submit">
                <validation-provider v-slot="{ errors }" name="email" rules="required|email">
                  <v-alert v-show="invalidData.length"
                  :key="index+'dfsdf'"
                  v-for="(err, index) of invalidData" dense outlined type="error">{{err}}</v-alert>
                  <v-alert
                    dense
                    outlined
                    type="success"
                    v-if="verfied"
                  >{{$t('resend.weSentYouEmailVerificationLink')}} </v-alert>
                  <v-text-field
                    v-model="email"
                    :error-messages="errors"
                    :label="$t('validations.fields.email')" 
                    type="email"
                    required
                    single-line
                    outlined
                    height="50"
                  ></v-text-field>
                </validation-provider>
          
               

                <v-btn
                  large
                  block
                  :loading="loading"
                  color="primary"
                  
                  type="submit"
                  :disabled="invalid"
                >{{$t('resend.ResendInstructions')}}</v-btn>
              </form>
              
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>



export default {
 
  data: () => ({
    email: "",
    invalidData: false,
    loading: false,
    verfied: "",
  }),

  mounted() {
  },
  methods: {
  
    submit(){
          this.loading=true

        this.$store
        .dispatch("auth/resendEmail", {email:this.email}).then(res=>{
          this.verfied=true
          this.loading=false
        }).catch(err=>{
          this.loading=false
            this.invalidData = err.errors;


        })
    },
    
  },
};
</script>

<style>
.auth-title {
  font-size: 25px !important;
  font-weight: bold !important;
}
</style>