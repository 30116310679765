import endpoints from "../common/endpoints";
import http from "../common/http";
const profileUrl = endpoints.PROFILE_ENDPOINT;
const paymentsUrl = endpoints.PROFILE_PAYMENTS_ENDPOINT;
const subscriptionsUrl = endpoints.PROFILE_SUBSCRIPTIONS_ENDPOINT;
const updateProfileUrl = endpoints.UPDATE_PROFILE_ENDPOINT;
const updatePasswordUrl = endpoints.UPDATE_PASSWORD_ENDPOINT;


export default{
    getProfile() {
        return http.get(profileUrl).then((res)=>{
            return res
        })
    },
     getPayments(page) {
        return http.get(paymentsUrl, page).then((res)=>{
            return res
        })
    },
    getSubscriptions(status) {
        return http.get(subscriptionsUrl, {
            params: {
              status: status,
            }, 
          }).then((res)=>{
            return res
        })
    },
    
    updateProfile(data) {
        return http.post(updateProfileUrl,data).then((res)=>{
            return res
        })
    },
    updatePassword(data) {
        return http.post(updatePasswordUrl,data).then((res)=>{
            return res
        })
    },

  

}