<template>
      <v-container class="text-center">
        <p  :class="$i18n.locale == 'en' ?'text-left':'text-right'">{{lectures.description}}
        </p>
      <v-btn
  elevation="2"
  x-large
  rounded
  color="primary"
  @click="downloadFile()"
>
{{$t('lectures.DownloadFile')}}
   <v-icon
        right
        dark
      >
        mdi-cloud-download
      </v-icon>
</v-btn>
      </v-container>
</template>

<script>
import { saveAs } from "file-saver";
import lecturesModule from "@/store/modules/lectures"; 

export default {
 
  computed: {
    lectures() {
      return this.$store.state.lectures.lectures;
    },
  },
  methods: {
    downloadFile() {

      this.lectures.contents.filter((e) => {
        if (e.type == "file") {
          this.$store
            .dispatch("lectures/downloadLectuter", e.id)
            .then((res) => {
              var file = new File([res], "file.txt", {
                type: "text/plain;charset=utf-8",
              });
              saveAs(file);
              this.loader = "success";
            })
            .catch(() => {
              this.loader = "error";
            });
        }
      });
    },
       getLecturesById(id) {
      
      this.$store
        .dispatch("lectures/getLecturesById", id)
        .then(() => {
          this.loader = "success";
        })
        .catch(() => {
          this.loader = "error";
        });
    },

  },
  created() {
    if (!lecturesModule.isRegistered) {
      this.$store.registerModule("lectures", lecturesModule);
      lecturesModule.isRegistered = true;
    }
    if (this.$route.params.id) {
      this.getLecturesById(this.$route.params.id);
    }
  },
  watch: {
    $route(val) {
      if (val.params.id) {
        this.getLecturesById(val.params.id);
      }

      // this.getCourses(val.query);
    },
  },

};
</script>

<style>
</style>