import Vue from "vue";
import axios from "axios";
import store from "../store";
import endpoints from "./endpoints";
import i18n from "../i18n";

const $domain_api = endpoints.API_URL;
const token = localStorage.getItem("7asety_token");

const base = axios.create({
  baseURL: $domain_api,
  headers: !token
    ? {
        "localization-x":
          localStorage.getItem("direction") == "rtl" ? "ar" : "en",
      }
    : {
        Authorization: "Bearer " + token,
        "localization-x":
          localStorage.getItem("direction") == "rtl" ? "ar" : "en",
      },
});

// before a request is made start the nprogress
base.interceptors.request.use((config) => {
  // store.dispatch('toggleShow');
  if (config.method == "get") {
    store.dispatch("toggleShowLoaderOverlay");
  }
  return config;
});

base.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    //    store.dispatch('toggleHide');
    store.dispatch("toggleHideLoaderOverlay");
    if (response.config.method == "post") {

      if(response.config.url.includes('teachers')){
        store.commit("SHOW_MESSAGE", {
            text: i18n.t('alert.successAlertTeacher'),
            color: "success",
            timeout: 3500,
          });
    }
    else if (response.config.url.includes('password/forgot') || response.config.url.includes('email/resend')) {
      store.commit("SHOW_MESSAGE", {
        text: i18n.t('alert.forgetAlert'),
        color: "success",
        timeout: 3500,
      });
    }
    else if (response.config.url.includes('password/reset')) {
      store.commit("SHOW_MESSAGE", {
        text: i18n.t('alert.resetAlert'),
        color: "success",
        timeout: 3500,
      });
    }
    else if (response.config.url.includes('payment') && response.data.response.data.three_d_secured == null) {
      store.commit("SHOW_MESSAGE", {
        text: i18n.t('alert.fawryAlert'),
        color: "success",
        timeout: 3500,
      });
    }
    else if (response.config.url.includes('enrollment')) {
      store.commit("SHOW_MESSAGE", {
        text: i18n.t('alert.enrollAlert'),
        color: "success",
        timeout: 3500,
      });
    }
    else if (response.config.url.includes('contact-us')) {
      store.commit("SHOW_MESSAGE", {
        text: i18n.t('alert.contactusAlert'),
        color: "success",
        timeout: 3500,
      });
    }
    else if (response.config.url.includes('register')) {
      store.commit("SHOW_MESSAGE", {
        text: i18n.t('alert.registerAlert'),
        color: "success",
        timeout: 3500,
      });
    }
    else if (response.config.url.includes('checkEmail')){
      store.commit("");
    }
    else if (response.config.url.includes('review')){
      store.commit("SHOW_MESSAGE", {
        text: i18n.t("alert.successAlert"),
        color: "success",
        timeout: 3500,
      });
    }
     else if (!response.config.url&&response.config.url.includes("exam/lecture")>-1) {
        

        store.commit("SHOW_MESSAGE", {
          text: i18n.t("alert.successAlert"),
          color: "success",
          timeout: 3500,
        });
      }
      else {
      //     store.commit("SHOW_MESSAGE", {
      //     text: i18n.t("alert.successAlert"),
      //     color: "success",
      //     timeout: 3500,
      //   });
      }
    }
   response.data.headers = response.headers   ;

    return response.data;
  },
  function(error) {
    store.dispatch("toggleHideLoaderOverlay");
       let errorMessage = error.response.data.errors[0];
   
    //    store.dispatch('toggleHide');
    if (error.response.data.status == 401) {
      store.commit("SHOW_MESSAGE", {
        text: i18n.t("alert.unauthorizedAlert"),
        color: "red darken-2",
        timeout: 3500,
      });
    }
    if (error.response.data.status == 500) {
      store.commit("SHOW_MESSAGE", {
        text: i18n.t("alert.serverError"),
        color: "red darken-2",
        timeout: 3500,
      });
    } 
    if (error.response.data.status == 422 && (errorMessage =="The email must be a valid email address." || errorMessage =="The email field is required.") ) {
      store.commit("");
    } 
        //  SNACKBAR MESSAGE
    else {
      store.commit("SHOW_MESSAGE", {
        text: errorMessage,
        color: "red darken-2",
        timeout: 3500,
      });
    }

       // ALERT MESSAGE
       if(error.response.data.status) {
        store.commit("SHOW_ERROR", {
          error: errorMessage
        });
      } 
    return Promise.reject(error.response.data);
  }
);

Vue.prototype.$http = base;
export default base;
