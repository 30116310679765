import teachers from "../../../services/teachers";


export default {
  
    becomeTeacher({ commit }, data) {
    return new Promise((resolve, reject) => {
        teachers
        .becomeTeacher(data)
        .then((res) => {
            resolve(res.response.data);  
        })
        .catch((err) => {
       
          return reject(err);
        });
    });
  },

  getTeachers({ commit },params) {
    return new Promise((resolve, reject) => {
      teachers.getTeachers(params)
        .then((resp) => {
            commit("SET_TEACHERS",  resp.response);
            resolve( resp.response.data);
          
        })
        .catch((resp) => {
       
          return reject(resp);
        });
    });
  },
 
};
