<template>
  <div>
      <!-- <Breadcrumb/> -->
     
        <v-row no-gutters>
              <v-col :class="{'my-12':$vuetify.breakpoint.mdAndUp}" md="5" class="pt-8">
                
              
  <v-card
    height="280"
    width="300"
    class="mx-auto"
  >
    <v-navigation-drawer permanent width="300">
             <v-list-item three-line>
              <v-list-item-avatar>
                <v-avatar size="40" color="primary">
                  <span
                    class="white--text text-h6 text-center"
                  >{{(userInfo.first_name +' '+ userInfo.last_name ) |short_hand}}</span>
                </v-avatar> 
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{userInfo.first_name +' '+userInfo.last_name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{userInfo.email}}
                  
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
      <v-divider></v-divider>

     <v-list
          nav
          dense
          class="navigation-items"
        >
          <v-list-item link  to="/profile/student-info" >
             {{$t('account.MyProfile')}}
          </v-list-item>
          <v-list-item link to="/profile/password">
             {{$t('account.Password')}}
          </v-list-item>
          <v-list-item link to="/profile/payments">
             {{$t('account.Payments')}}
          </v-list-item>
           <v-list-item link to="/profile/subscriptions">
              {{$t('account.Subscriptions')}}
          </v-list-item>
        </v-list>
    </v-navigation-drawer>
  </v-card>


              </v-col>
              <v-col :class="{'my-12':$vuetify.breakpoint.mdAndUp}" sm="5" md="5" lg="6">
                 <router-view/>
              </v-col>
        </v-row>
      
     
  </div>
</template>

<script>
import Breadcrumb from "../../components/breadcrumb/Breadcrumb.vue";
import { mapGetters } from "vuex";

export default {
  components:{
    Breadcrumb
  },
     filters: {
    short_hand(company_name) {
      // You can put your logic here...
      let words = company_name.split(" ");
      let short_hand = words[0][0] + words[words.length - 1][0];
      return short_hand; // <-- The return value as Per logic
    },
  },

   computed: {
    ...mapGetters({
      userInfo: "auth/userData",
    }),
  },
}
</script>

<style>
.navigation-items{
  font-size: 15.5px;
}
</style>