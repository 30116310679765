<template>
  <v-container fill-height="fill-height">
    <v-layout class="courses">
      <v-navigation-drawer v-model="drawer" app temporary bottom right v-if="!$vuetify.breakpoint.mdAndUp">
        <v-list subheader flat class="pt-2">
          <p class="mb-0 overflow-auto">
            <b style="vertical-align: sub;">{{$t('courses.Subject')}}</b>
            <v-btn
              @click="resetFilters"
              :class="$i18n.locale=='en'?'float-right':'float-left'"
              text
              color="error"
            >
               {{$t('courses.ResetFilters')}}
            </v-btn>
          </p>

          <v-list-item-group>
            <v-list-item
              :ripple="false"
              :class="$i18n.locale=='en'?'pl-0':'pr-0'"
              :key="index+'subject'"
              v-for="(subject, index) of subjects"
            >
              <template>
                <v-list-item-action class="mr-1">
                  <v-checkbox v-model="subject_id" :value="subject.id" color="primary"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-subtitle>{{subject[$i18n.locale].name}}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-divider></v-divider>
        <v-list subheader flat class="pt-2">
          <p class="mb-0">
            <b>{{$t('courses.Teacher')}}</b>
          </p>

          <v-list-item-group>
            <v-list-item
              :ripple="false"
              :class="$i18n.locale=='en'?'pl-0':'pr-0'"
              :key="index+'teacher'"
              v-for="(teacher, index) of teachers"
            >
              <template>
                <v-list-item-action class="mr-1">
                  <v-checkbox v-model="teacher_id" :value="teacher.id" color="primary"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-subtitle>{{teacher[$i18n.locale].name}}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-divider></v-divider>
        <v-list subheader flat class="pt-2">
          <p class="mb-0">
            <b>{{$t('courses.Grade')}}</b>
          </p>

          <v-list-item-group>
            <v-list-item
              :ripple="false"
              :class="$i18n.locale=='en'?'pl-0':'pr-0'"
              :key="index+'grade'"
              v-for="(grade, index) of grades"
            >
              <template>
                <v-list-item-action class="mr-1">
                  <v-checkbox v-model="grade_id" :value="grade.id" color="primary"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-subtitle>{{grade[$i18n.locale].name}}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-row :class="{'my-12':$vuetify.breakpoint.mdAndUp}">
        <v-col lg="9" cols="12" :order="$vuetify.breakpoint.mdAndDown?'last':'first'">
          <div class="courses__search-list">
            <v-row class="pt-5" :no-gutters="$vuetify.breakpoint.smAndDown">
              <v-col md="4" cols="6" :class="{'my-auto':$vuetify.breakpoint.mdAndDown}" >
                <span :class="$i18n.locale=='en'?'mr-5':'ml-5'">
                  <span class="total-courses">{{coursesMeta.total}}</span>
                  {{$t('courses.Courses')}}
                </span>
              </v-col>
              <v-col   v-if="!$vuetify.breakpoint.mdAndUp" cols="6" class="mb-3" :class="$i18n.locale=='en'?'text-right':'text-left'">
                <v-btn
               
                  fab
                  elevation="0"
                  @click.stop="drawer = !drawer"
                  color="error"
                >
                  <v-icon color="white">mdi-filter-menu</v-icon>
                </v-btn>
              </v-col>
              <v-col :class="$i18n.locale=='en'?'ml-auto':'mr-auto'" md="3" cols="12">
                <v-select
                  :items="sorting"
                  v-model="sort_id"
                  item-value="id"
                  :label="$t('courses.Newlypublished')"
                  solo
                >
                  <template v-slot:selection="{ item }">{{ $t(item.name) }}</template>
                  <template v-slot:item="{  item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs" #default="{  }">
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-row no-gutters align="center">
                            <span>{{ $t(item.name) }}</span>
                            <v-spacer></v-spacer>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col md="4" cols="12">
                <v-text-field
                  :label="$t('courses.SearchOurCourses')"
                 
                  solo
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div class="loader" v-if="loader=='loadingcourse' ||loader=='nocourses'||loader=='error'">
            <v-progress-circular
              v-if="loader=='loadingcourse'"
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <v-list-item-subtitle
              v-if="loader=='nocourses'"
              class="warm_gray--text text-center"
            >{{$t('courses.CourseNotFound')}}</v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="loader=='error'"
              class="danger--text text-center"
            >{{$t('courses.ErrorOccured')}}</v-list-item-subtitle>
          </div>
          <v-hover
            v-slot="{ hover }"
            v-show="loader=='success' "
            :key="index+'course'"
            v-for="(course, index) of courses"
          >
            <v-card elevation="0" class="mb-5">
              <v-list-item class="courses__list-item" :to="`course/${course.id}`">
                <v-img
                  :src="course.image"
                  :lazy-src="course.image"
                  :class="$i18n.locale=='en'?'mr-2':'ml-2'"
                  max-height="150"
                  max-width="250"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out primary darken-2 v-card--reveal text-p white--text"
                      style="height: 100%;     width: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}"
                    >{{$t('courses.PreviewCourse')}}</div>
                  </v-expand-transition>
                </v-img>
                <v-list-item-content>
                  <div class="warm_gray--text">{{course[$i18n.locale].teacher_name}}</div>
                  <v-list-item-title class="text-h5 my-2">{{course[$i18n.locale].name}}</v-list-item-title>
                  <v-list-item-subtitle class="warm_gray--text">{{course[$i18n.locale].description}}</v-list-item-subtitle>

                  <v-card-actions class="px-0">
                    <v-list-item class="grow px-0">
                      <v-row no-gutters align="center" justify="start">
                        <v-col md="5" cols="12">
                          <v-icon>mdi-account</v-icon>
                          <span
                            class="subheading"
                            :class="$i18n.locale=='en'?'mr-2':'ml-2'"
                          >{{course.no_enrollments || '0'}}</span>

                          <v-icon>mdi-comment-account-outline</v-icon>
                          <span class="subheading">{{course.no_comments ||'0'}}</span>
                        </v-col>

                        <v-col md="7" cols="12" :class="$i18n.locale=='en'?'text-right':'text-left'"
>
                          <v-rating
                            class="d-inline"
                            readonly
                            background-color="orange lighten-3"
                            color="orange"
                            size="16"
                            :value="course.rate || 0"
                          ></v-rating>
                          ({{course.rate || '0'}})
                        </v-col>
                      </v-row>
                    </v-list-item>
                  </v-card-actions>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-hover>
        </v-col>
        <v-col lg="3" cols="12" v-if="$vuetify.breakpoint.mdAndUp">
          <v-list subheader flat>
            <p class="mb-0 overflow-auto">
              <b style="vertical-align: sub;">{{$t('courses.Subject')}}</b>
            
              <v-btn   :class="$i18n.locale=='en'?'float-right':'float-left'" @click="resetFilters" class="float-"  text color="error">
               {{$t('courses.ResetFilters')}}
              </v-btn>
            </p>

            <v-list-item-group>
              <v-list-item
                :ripple="false"
                :class="$i18n.locale=='en'?'pl-0':'pr-0'"
                :key="index+'subject'"
                v-for="(subject, index) of subjects"
              >
                <template>
                  <v-list-item-action class="mr-1">
                    <v-checkbox v-model="subject_id" :value="subject.id" color="primary"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{subject[$i18n.locale].name}}
                      <span
                        :class="$i18n.locale=='en'?'float-right':'float-left'"
                      >({{subject.number_of_courses}})</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-list subheader flat>
            <p class="mb-0 ">
              <b>{{$t('courses.Teacher')}}</b>
            </p>

            <v-list-item-group>
              <v-list-item
                :ripple="false"
                :class="$i18n.locale=='en'?'pl-0':'pr-0'"
                :key="index+'teacher'"
                v-for="(teacher, index) of teachers"
              >
                <template>
                  <v-list-item-action class="mr-1">
                    <v-checkbox v-model="teacher_id" :value="teacher.id" color="primary"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{teacher[$i18n.locale].name}}
                      <span
                        :class="$i18n.locale=='en'?'float-right':'float-left'"
                      >({{teacher.number_of_courses }})</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-list subheader flat>
            <p class="mb-0">
              <b>{{$t('courses.Grade')}}</b>
            </p>

            <v-list-item-group>
              <v-list-item
                :ripple="false"
                :class="$i18n.locale=='en'?'pl-0':'pr-0'"
                :key="index+'grade'"
                v-for="(grade, index) of grades"
              >
                <template>
                  <v-list-item-action class="mr-1">
                    <v-checkbox v-model="grade_id" :value="grade.id" color="primary"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{grade[$i18n.locale].name}}
                      <span
                        :class="$i18n.locale=='en'?'float-right':'float-left'"
                      >({{grade.number_of_courses}})</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col   v-if="coursesMeta.last_page >1" cols="12" order="last">
          <v-pagination
            class="my-5"
          
            :length="coursesMeta.last_page"
            v-model="page"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import coursesModule from "@/store/modules/courses";

export default {
  data: () => ({
    page: 1,
    subject_id: null,
    teacher_id: null,
    grade_id: null,
    loader: null, 
    search: "",
    sort_id: null,
    drawer: false,
    sorting: [
      { id: "desc", name: "courses.Latestpublished" },
      { id: "asc", name: "courses.oldestpublished" },
    ],
  }),
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      // this.fab = top > 20;
    },
    getGrades() {
      this.loader = "loading";
      this.$store
        .dispatch("courses/getGrades")
        .then(() => {
          this.loader = "success";
        })
        .catch(() => {
          this.loader = "error";
        });
    },
    getTeachers() {
      this.loader = "loading";
      this.$store
        .dispatch("courses/getTeachers")
        .then(() => {
          this.loader = "success";
        })
        .catch(() => {
          this.loader = "error";
        });
    },
    getSubjects() {
      this.loader = "loading";
      this.$store
        .dispatch("courses/getSubjects")
        .then(() => {
          this.loader = "success";
        })
        .catch(() => {
          this.loader = "error";
        });
    },
    getCourses(query) {
      this.page = +query.page;
      // this.subject_id = +query.subject;
      // this.teacher_id = +query.teacher;
      // this.grade_id = +query.grade;
      // this.search = query.search;
      // this.sort_id = query.sorting;
      this.$router.push({
        query: Object.assign({}, this.$route.query, { page: query.page }),
      });

      const params = {
        page: query.page,
        teacher_id: query.teacher,
        subject_id: query.subject,
        grade_id: query.grade,
        search: query.search,
        sorting: query.sorting,
      };

      this.loader = "loadingcourse";
      this.$store
        .dispatch("courses/getCourses", params)
        .then((res) => {
          if (res.length) {
            this.loader = "success";
          } else {
            this.loader = "nocourses";
          }
        })
        .catch(() => {
          this.loader = "error";
        });
    },
    resetFilters() {
      this.$router.push({
        query: { page: 1 },
      });
       this.grade_id = '';
       this.subject_id = '';
       this.teacher_id = '';
    },
  },
  watch: {
    $route(val) {
      this.getCourses(val.query);
    },
    page(obj) {
      this.page = +obj || 1;
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          page: this.page,
        }),
      });
    },
    grade_id(obj) {
      let query = Object.assign({}, this.$route.query);

      delete query.grade;
      if (obj) {
        this.$router.push({
          query: Object.assign({}, this.$route.query, {
            grade: this.grade_id,
          }),
        });
      } else {
        this.$router.replace({ query });
      }
    },
    subject_id(obj) {
      let query = Object.assign({}, this.$route.query);
      this.subject_id = obj;

      delete query.subject;
      if (obj) {
        this.$router.push({
          query: Object.assign({}, this.$route.query, {
            subject: this.subject_id,
          }),
        });
      } else {
        this.$router.replace({ query });
      }
    },
    teacher_id(obj) {
      let query = Object.assign({}, this.$route.query);

      delete query.teacher;
      if (obj) {
        this.$router.push({
          query: Object.assign({}, this.$route.query, {
            teacher: this.teacher_id,
          }),
        });
      } else {
        this.$router.replace({ query });
      }
    },
    search(val) {
      let query = Object.assign({}, this.$route.query);

      delete query.search;
      if (val) {
        this.$router.push({
          query: Object.assign({}, this.$route.query, {
            search: val,
          }),
        });
      } else {
        this.$router.replace({ query });
      }
    },
    sort_id(val) {
      let query = Object.assign({}, this.$route.query);

      delete query.search;
      if (val) {
        this.$router.push({
          query: Object.assign({}, this.$route.query, {
            sorting: this.sort_id,
          }),
        });
      } else {
        this.$router.replace({ query });
      }
    },
  },

  computed: {
    grades() {
      return this.$store.state.courses.grades;
    },
    subjects() {
      return this.$store.state.courses.subjects;
    },
    teachers() {
      return this.$store.state.courses.teachers;
    },
    courses() {
      return this.$store.state.courses.courses;
    },
    coursesMeta() {
      return this.$store.state.courses.coursesMeta;
    },
  },
  created() {
    if (!coursesModule.isRegistered) {
      this.$store.registerModule("courses", coursesModule);
      coursesModule.isRegistered = true;
    }
    this.getGrades();
    this.getTeachers();
    this.getSubjects();
    if (this.$route.query) {
      this.getCourses(this.$route.query);
    }
  },
};
</script>

<style>
</style>