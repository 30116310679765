<template>
  <v-app>
    <!-- HEADER -->

    <Header />
    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->

      <router-view :key="$route.fullPath"></router-view>
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        bottom
        right
        color="primary"
        @click="toTop"
      >
        <v-icon>mdi-apple-keyboard-control</v-icon>
      </v-btn>
    </v-main>
    <Snackbar />
    <!-- <LoaderOverlay v-if="loadingOverlay"/> -->

    <Footer />
  </v-app>
</template>
<script>
// import { mapActions } from 'vuex';
import Snackbar from "./components/notifier/Notifier.vue";
import Header from "./components/header/Header.vue";
import Footer from "./components/footer/Footer.vue";
// import LoaderOverlay from "./components/loader-overlay/LoaderOverlay.vue";

export default {
  //   ,
  // computed: {
  //    loadingOverlay() {
  //     return this.$store.getters.getloaderOverlay;
  //   },
  // },
  data: () => ({
    fab: false,
  }),

  components: {
    Snackbar,
    Header,
    Footer,
    // LoaderOverlay
  },
  methods: {
    changeRTL() {
      this.$vuetify.rtl = true;
    },

    toTop() {
      this.$vuetify.goTo(0);
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    setLocal() {
      const rtl = localStorage.getItem("direction");
      if (rtl == "rtl") {
        this.$vuetify.rtl = true;
      document.body.classList.add("rtl");

        this.$i18n.locale = "ar";
      } else {
        document.body.classList.remove(rtl);
        this.$vuetify.rtl = false;

      }
    },
    checkTokenUser() {
      if (
        localStorage.getItem( "7asety_token") =='undefined'&&  localStorage.getItem("7asety_user")=='undefined'
      ) {
        localStorage.removeItem("7asety_token");
        localStorage.removeItem("7asety_user");
      window.location.reload();

        
      }
    },
  },
  mounted() {
    this.checkTokenUser();

    this.setLocal();
  },
};
</script>