import global from "../../../services/global";



export default {
  
  postContactus({ commit }, data) {
    return new Promise((resolve, reject) => {
      global
        .postContactus(data)
        .then((res) => {
            resolve(res.response.data);
          
        })
        .catch((err) => {
       
          return reject(err);
        });
    });
  },
    
  getHome({ commit }, data) {
    return new Promise((resolve, reject) => {
      global
        .getHome()
        .then((res) => {
          commit("SET_HOME",res.response.data)
            resolve(res.response.data);
          
        })
        .catch((err) => {
       
          return reject(err);
        });
    });
  },

  generalSearch({ commit }, search) {
    return new Promise((resolve, reject) => {
      global
        .generalSearch(search)
        .then((res) => {
          commit("SET_SEARCH",res.response.data)
            resolve(res.response.data);
          
        })
        .catch((err) => {
       
          return reject(err);
        });
    });
  },
 
};
