
<template>
  <!---->
  <div class="teacher" id="scroll-down">
    <v-container>
      <div class="section-title">
        <h3>{{$t('home.Teachers.Teachers')}}</h3>
        <!-- <p>{{$t('home.Teachers.des')}}</p> -->
      </div>
      <v-carousel  cycle :show-arrows="teachers.length >5 && $vuetify.breakpoint.mdAndUp || teachers.length >1 && !$vuetify.breakpoint.mdAndUp" hide-delimiter-background height="auto">
        <!-- <template v-slot:prev="{ on, attrs }">
          <v-btn color="success" v-bind="attrs" v-on="on">Previous slide</v-btn>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-btn color="info" v-bind="attrs" v-on="on">Next slide</v-btn>
        </template> -->
        <template v-for="(item, index) in teachers">
          <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index">
            <v-row class="flex-nowrap" align="center">
              <template v-for="(n,i) in columns">
                <template v-if="(+index + i) < teachers.length">
                  <v-col :key="i">
                    <v-card :to="'courses?page=1&teacher='+teachers[+index + i].id" v-if="(+index + i) < teachers.length"  class="mx-auto text-center" max-width="344" elevation="0">
                      <v-avatar size="135" rounded="circle">
                 
                        <v-img alt="user" :lazy-src="teachers[+index + i].image" :src="teachers[+index + i].image" ></v-img>
                      </v-avatar>
                      <v-row align="center" class="mt-3" justify="center">
                        <v-rating
                          :value="teachers[+index + i].rate||0"
                          color="orange"
                          background-color="orange lighten-3"
                          readonly
                          size="16"
                        ></v-rating>

                        <v-card-subtitle class="pa-0">({{teachers[+index + i].number_of_courses}})</v-card-subtitle>
                      </v-row>

                      <v-card-title class="justify-center">{{teachers[+index + i][$i18n.locale].name}}</v-card-title>
                      <v-card-subtitle>{{teachers[+index + i].description}}</v-card-subtitle>
                    </v-card>
                  </v-col>
                </template>
              </template>
            </v-row>
          </v-carousel-item>
        </template>
      </v-carousel>
    </v-container>
  </div>
</template>

<script>
export default {


  data: () => ({
 
  }),
  
  computed: {
    teachers(){
      return this.$store.state.global.home.teachers;

    },
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 5;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 5;
      }

      if (this.$vuetify.breakpoint.md) {
        return 4;
      }
       if (this.$vuetify.breakpoint.sm) {
        return 1;
      }

      return 1;
    },
  },
};
</script>

<style>
</style>