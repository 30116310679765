export default {
  SET_GRADES(state, grades) {
    state.grades = grades;
  },
  SET_COURSES(state, courses) {
    state.courses = courses.data;
    state.coursesMeta=courses.meta
  },
  SET_SINGLE_COURSES(state, singleCourse) {
    state.singleCourse = singleCourse;
  },
  SET_SUBJECTS(state, subjects) {
    state.subjects = subjects;
  },
  SET_TEACHERS(state, teachers) {
    state.teachers = teachers;
  },
};
