const BASE_URL = process.env.VUE_APP_BASE_URL;

const V1 = "v1/";
const API_URL = BASE_URL + "/api/" +V1;
const SIGN_IN = "login";
const SIGN_UP = "register";
const PASSWORD="password/"
const FORGET_PASSWORD = "forgot";
const RESET_PASSWORD = "reset";
const RESEND_EMAIL="email/resend";
const CHECK_EMAIL= "checkEmail";

// COURSES
const COURSES = "courses";
const GRADES = "grades";
const REVIEW = "review";
const SUBJECTS = "subjects";
const TEACHERS = "teachers";

// STUDENT PROFILE
const PROFILE = "profile";
const UPDATE_PROFILE = "update/profile";
const UPDATE_PASSWORD = "update/password";
const PROFILE_PAYMENTS = "profile/payments";
const PROFILE_SUBSCRIPTIONS = "profile/subscriptions";


// LECTURES
const LECTURES = "lectures";
const DOWNLOAD_CONTENT="download/content"
const CHECKOUT="checkout"
const ENROLLMENT="enrollment"
const REVIEWS="reviews"


// CONTACT US 
const CONTACTUS = "contact-us";
const HOME = "home";


// EXAM
const EXAM="exam"
const LECTURE="/lecture"

// PAYMENT CHECKOUT
const PAYMENT= "payment";

// General Search
const GENERAL_SEARCH= "generalSearch";

export default {
    BASE_URL: BASE_URL,
    API_URL: API_URL,
    SIGN_IN_ENDPOINT :API_URL+ SIGN_IN,
    SIGN_UP_ENDPOINT: API_URL+SIGN_UP,
    FORGET_PASSWORD_ENDPOINT :API_URL+PASSWORD+ FORGET_PASSWORD,
    RESET_PASSWORD_ENDPOINT :API_URL+PASSWORD+ RESET_PASSWORD,
    RESEND_EMAIL_ENDPOINT :API_URL+ RESEND_EMAIL,
    CHECK_EMAIL_ENDPOINT :API_URL+ CHECK_EMAIL,
    GRADES_ENDPOINT :API_URL+ GRADES,
    COURSES_ENDPOINT :API_URL+ COURSES,
    REVIEW_ENDPOINT :API_URL+ REVIEW,
    SUBJECTS_ENDPOINT :API_URL+ SUBJECTS,
    TEACHERS_ENDPOINT :API_URL+ TEACHERS,

    PROFILE_ENDPOINT : API_URL+ PROFILE,
    UPDATE_PROFILE_ENDPOINT : API_URL+ UPDATE_PROFILE,
    UPDATE_PASSWORD_ENDPOINT : API_URL+ UPDATE_PASSWORD,
    PROFILE_PAYMENTS_ENDPOINT : API_URL+ PROFILE_PAYMENTS,
    PROFILE_SUBSCRIPTIONS_ENDPOINT : API_URL+ PROFILE_SUBSCRIPTIONS,

    LECTURES_ENDPOINT : API_URL+ LECTURES,
    DOWNLOAD_CONTENT_ENDPOINT : API_URL+ DOWNLOAD_CONTENT,
    CHECKOUT_ENDPOINT : API_URL+ CHECKOUT,
    ENROLLMENT_ENDPOINT : API_URL+ ENROLLMENT,
    REVIEWS_ENDPOINT : API_URL+ REVIEWS,



    CONTACTUS_ENDPOINT : API_URL+ CONTACTUS,
    HOME_ENDPOINT : API_URL+ HOME,

    EXAM_ENDPOINT:API_URL+EXAM,
    EXAM_LECTURE_ENDPOINT:API_URL+EXAM+LECTURE,

    PAYMENT_ENDPOINT : API_URL+ PAYMENT,

    GENERAL_SEARCH_ENDPOINT: API_URL+ GENERAL_SEARCH,
}
