<template>
  <v-container fill-height="fill-height">
    <v-layout align-center="align-center" justify-center="justify-center">
      <v-flex class="login-form text-xs-center">
        <v-card
          light="light"
          max-width="500"
          elevation="0"
          class="mx-auto "
          :class="{'my-12':$vuetify.breakpoint.mdAndUp}"
        >
          <v-list>
            <v-list-item class="text-center">
              <v-list-item-content>
                <h1 class="auth-title text-h4">{{$t('login.LoginToYourAccount')}}</h1>
                <div class="text-center text-h6" style="color: #6f7074">
                  {{$t('login.DontHaveAccount')}}
                  <v-btn text color="#79b530" to="/register" class="px-0 font-weight-medium text-h6"
                    >{{$t('signUp.SignUp')}}</v-btn
                  >
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-text>
              <v-alert
               v-show="invalidData"
               dense
               outlined
               type="error"
               >{{$t('alert.InvalidEmailorPassword')}}</v-alert
            >
            <v-alert dense outlined type="success" v-if="verfied"
               >{{$t('alert.youraccountverifiedsuccessfully')}}</v-alert
            >
            
            <validation-observer ref="observer" v-slot="{ invalid }">
              <form @submit.prevent="submit">
    
                <validation-provider
                  v-slot="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <v-text-field
                    v-model="email"
                    :error-messages="errors"
                    :label="$t('validations.fields.email')"
                    type="email"
                    required
                    single-line
                    outlined
                    height="50"
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="password"
                  rules="required|min:8"
                >
                  <v-text-field
                    v-model="password"
                    :type="show ? 'text' : 'password'"
                    :error-messages="errors"
                    :label= "$t('validations.fields.password')"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show = !show"
                    @click.right.prevent 
                    @copy.prevent 
                    @paste.prevent
                    required
                    single-line
                    outlined
                    height="50"
                  ></v-text-field>
                </validation-provider>
                <v-row no-gutters>
                  <v-col md="6" style="visibility: hidden;">
                    <v-checkbox
                      v-model="rememberMe"
                      :label="$t('login.RememberMe')"
                      class="mt-0"
                    ></v-checkbox>
                  </v-col>

                  <v-col md="6" :class="$i18n.locale =='en'?'text-right':'text-left'">
                    <v-btn
                      text
                      color="#c75533"
                      class="px-0"
                      to="/forget-password"
                      >{{$t('login.ForgetPassword')}}</v-btn
                    >
                  </v-col>
                </v-row>

                <v-btn
                  large
                  block
                  :loading="loading"
                  color="primary"
                  
                  type="submit"
                  :disabled="invalid"
                  >{{$t('header.Login')}}</v-btn
                >
              </form>
              <p class="mt-3 text-center">
                {{$t('login.HaventReceivedConfirmationEmailYet')}}
                <v-btn to="/resend-email" text color="primary">
                  {{$t('login.ResendTheEmail')}}</v-btn
                >
              </p>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  data: () => ({
    email: "",
    password: "",
    invalidData: false,
    rememberMe: true,
    loading: false,
    verfied: "",
    show: false,
  }),


  mounted() {
    this.verfied = this.$route.query.verified;
  },
  methods: {
    submit() {
      // this.$refs.observer.validate();
      this.loading = true;
      const email = this.email;
      const password = this.password;
      const rememberMe = this.rememberMe;
      this.$store
        .dispatch("auth/signIn", {
          email,
          password,
          rememberMe,
        })
        .then((res) => {
            this.loading = false;
            if(localStorage.getItem("7asety_lectureId")){
            // this.$router.push("/lecture/" + localStorage.getItem("7asety_lectureId"));
            }
            else{
            }
            
            this.$router.push("/home");
         
        })
        .catch((err) => {
          if (err) {
            this.invalidData =true;
            this.verfied = false;
            this.loading = false;
          }
        });
    },
  },
};
</script>

<style>
.auth-title {
  font-size: 25px !important;
  font-weight: bold !important;
}
</style>