<template>
  <div class="home">
    <Hero />
    <Teachers  v-if="loading"/>
    <TopCourses  v-if="loading"/>
    <Categories  v-if="loading"/>
    <WhatWeDo />
    <JoinAndBecome />
  </div>
</template>

<script>
import WhatWeDo from "./components/WhatWeDo.vue";
import TopCourses from "./components/TopCourses.vue";
import Teachers from "./components/Teachers.vue";
import JoinAndBecome from "./components/JoinAndBecome.vue";
import Hero from "./components/Hero.vue";
import Categories from "./components/Categories.vue";
import globalModule from "@/store/modules/global";

export default {
  data: () => ({
    loading:false,
    
  }),
  components: {
    WhatWeDo,
    TopCourses,
    Teachers,
    JoinAndBecome,
    Hero,
    Categories,
  },
  
  created() {
    if (!globalModule.isRegistered) {
      this.$store.registerModule("global", globalModule);
      globalModule.isRegistered = true;
    }
    this.getHome()

  },

  methods:{
    getHome(){
      this.$store
        .dispatch("global/getHome").then(res=>{
          this.loading=true
        })  
    }
  },
  
};
</script>
