<template>
  <v-container fill-height="fill-height">
    <v-layout align-center="align-center" justify-center="justify-center">
      <v-flex class="login-form text-xs-center">
        <v-card light="light" max-width="700" elevation="0" class="mx-auto">
          <v-card-text>
            <validation-observer ref="observer" v-slot="{ invalid }">
              <form @submit.prevent="submit">
                <validation-provider
                  v-slot="{ errors }"
                  name="first name"
                  :rules="{required:true,max:20,min:2,alpha_spaces:true
}"
                >
                  <v-text-field
                    v-model="first_name"
                    :error-messages="errors"
                    :label="$t('validations.fields.firstName')"
                    type="text"
                    required
                    single-line
                    outlined
                    height="50"
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="middle name"
                  :rules="{required:true,max:20,min:2,alpha_spaces:true


}"
                >
                  <v-text-field
                    v-model="middle_name"
                    type="text"
                    :error-messages="errors"
                    :label="$t('validations.fields.middleName')"
                    required
                    single-line
                    outlined
                    height="50"
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="last name"
                  :rules="{required:true,max:20,min:2,alpha_spaces:true
}"
                >
                  <v-text-field
                    v-model="last_name"
                    :error-messages="errors"
                    :label="$t('validations.fields.LastName')"
                    type="text"
                    required
                    single-line
                    outlined
                    height="50"
                  ></v-text-field>
                </validation-provider>
                <validation-provider v-slot="{ errors }" name="email" rules="required|email">
                  <v-text-field
                    v-model="email"
                    :error-messages="errors"
                    :label="$t('validations.fields.email')"
                    type="email"
                    required
                    single-line
                    outlined
                    @blur="()=>{email=email.toLowerCase()}"
                    height="50"
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="ParentNumber"
                  :rules="{required:true,
           mobileregex:true,
          numeric:true}"
                >
                  <v-text-field
                    v-model="parent_number"
                    :error-messages="errors"
                    :label="$t('validations.fields.ParentNumber')"
                    type="text"
                    required
                    single-line
                    outlined
                    height="50"
                  ></v-text-field>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="phoneNumber"
                  :rules="{required:true,
  mobileregex:true,          numeric:true,
          }"
                >
                  <v-text-field
                    v-model="mobile"
                    :error-messages="errors"
                    :label="$t('validations.fields.phoneNumber')"
                    type="text"
                    required
                    single-line
                    outlined
                    height="50"
                  ></v-text-field>
                </validation-provider>
                 <validation-provider
                          v-slot="{ errors }"
                          name="Dateofbirth"
                          
                        >
                          <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                append-icon="mdi-calendar"
                                readonly  
                                @click:append="on.click"     
                                v-bind="attrs"
                                v-on="on"
                                v-model="dob"
                                :error-messages="errors"
                                :label="$t('validations.fields.Dateofbirth')"
                                single-line
                                outlined
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              :locale="$i18n.locale =='ar'?'ar-EG-u-nu-latn':''"
                              v-model="dob"
                              :active-picker.sync="activePicker"
                              :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                              min="1990-01-01"
                              @change="saveDateOfBirth"
                            ></v-date-picker>
                          </v-menu>
                        </validation-provider>
                <validation-provider v-slot="{ errors }" name="Your Grade" rules="required">
                  <v-select
                    v-model="grade_id"
                    :error-messages="errors"
                    :items="grades"
                    :item-text="grades => grades[$i18n.locale].name"
                    item-value="id"
                    :label="$t('validations.fields.YourGrade')"
                    type="text"
                    required
                    single-line
                    outlined
                    height="50"
                  ></v-select>
                </validation-provider>

                <v-btn
                  large
                  block
                  :loading="loading"
                  color="primary"
                  type="submit"
                  :disabled="invalid"
                >{{$t('validations.fields.Submit')}}</v-btn>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import coursesModule from "@/store/modules/courses";
import profileModule from "@/store/modules/profile";

export default {
  data: () => ({
    id: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    mobile: "",
    parent_number: "",
    dob: "",
    grade_id: "",
    loader: null,
    loading : false,
    invalidData: false,
  }),

  computed: {
    grades() {
      return this.$store.state.courses.grades;
    },
    profileInfo() {
      return this.$store.state.profile.profileInfo;
    },
  },

  mounted() {
    this.getGrades();
     this.getProfile();
  },

  methods: {
    getProfile() {
      this.loader = "loading"; 
      this.$store
        .dispatch("profile/getProfile")
        .then((res) => {
          // error from backend
          this.loader = "success";
          this.first_name = res.first_name;
          this.middle_name = res.middle_name;
          this.last_name = res.last_name;
          this.email = res.email;
          this.parent_number = res.parent_number;
          this.mobile = res.mobile;
          this.dob = res.dob;
          this.grade_id = res.grade.id;
        })
        .catch(() => {
          this.loader = "error";
        });
    },
    getGrades() {
      this.$store.dispatch("courses/getGrades");
    },
    submit() {
      // this.$refs.observer.validate();
      this.loader = true;
      const profile = {
        first_name: this.first_name,
        middle_name: this.middle_name,
        last_name: this.last_name,
        mobile: this.mobile,
        parent_number: this.parent_number,
        email: this.email,
        dob: this.dob,
      };

      this.$store
        .dispatch("profile/updateProfile", profile)
        .then((res) => {
          this.loading = false;
        })
        .catch((err) => {
          if (err) {
            this.invalidData = err.errors;

            this.loading = false;
          }
        });
    },
  },
  
  created() {
     if (!coursesModule.isRegistered) {
      this.$store.registerModule("courses", coursesModule);
      coursesModule.isRegistered = true;
    }
       if (!profileModule.isRegistered) {
      this.$store.registerModule("profile", profileModule);
      profileModule.isRegistered = true;
    }
   


  },
};
</script>
