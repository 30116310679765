import Vue from 'vue';
import { ValidationProvider, extend, ValidationObserver, setInteractionMode } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import i18n from '../i18n';

setInteractionMode("aggressive");

Vue.component('validation-observer', ValidationObserver);
Vue.component('validation-provider', ValidationProvider);


/**
 * Translate validation rules
 */
for (const [key, value] of Object.entries(rules)) {

   extend(`${key}`, {
      ...value,
      // the values param is the placeholders values
      message: (field, values) => {
         values._field_ = i18n.te(`validations.fields.${field}`) ? i18n.t(`validations.fields.${field}`) : field;
         return i18n.t(`validations.messages.${key}`, values);
      }
   });
}

/**
 * Custom rules
 */
extend("mobileregex", value => {

   if(/^(\\+20|0020|020|20|0)?1(0|1|2|5)[0-9]{8}$/.test(value)){
      return true;
   }

   return i18n.t(`validations.messages.mobileregex`);
});


extend("passwordRegex", value => {

   if(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,25}$/.test(value)){
      return true;
   }

   return i18n.t(`validations.messages.passwordregex`);
});


extend("dublicatedmobile", {
   params: ["target"],
   validate(value, { target }) {
     return value !== target;
   },
   message: () => i18n.t("validations.messages.dublicatedmobile")
 });