var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fill-height":"fill-height"}},[_c('v-layout',{attrs:{"align-center":"align-center","justify-center":"justify-center"}},[_c('v-flex',{staticClass:"login-form text-xs-center"},[_c('v-card',{staticClass:"mx-auto",class:{'my-12':_vm.$vuetify.breakpoint.mdAndUp},attrs:{"light":"light","max-width":"500","elevation":"0"}},[_c('v-list',[_c('v-list-item',{staticClass:"text-center"},[_c('v-list-item-content',[_c('h1',{staticClass:"auth-title"},[_vm._v(_vm._s(_vm.$t('contactus.title')))])])],1)],1),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('form',{ref:"afterSubmit",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"name","rules":{required:true,min:3,max:30,alpha_spaces:true
}},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('validations.fields.name'),"type":"text","required":"","single-line":"","outlined":"","height":"50"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_vm._l((_vm.invalidData),function(err,index){return _c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.invalidData.length),expression:"invalidData.length"}],key:index+'dfsdf',attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(_vm._s(err))])}),_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('validations.fields.email'),"type":"email","required":"","single-line":"","outlined":"","height":"50"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"phoneNumber","rules":{required:true,
                                   numeric:true,
                                   mobileregex:true       
                         }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('validations.fields.phoneNumber'),"type":"text","required":"","single-line":"","outlined":"","height":"50"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"subject","rules":{required:true,max:40,min:2
}},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('validations.fields.subject'),"type":"text","required":"","single-line":"","outlined":"","height":"50"},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"message","rules":{required:true, max:200
}},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-textarea',{attrs:{"error-messages":errors,"label":_vm.$t('validations.fields.message'),"type":"text","required":"","single-line":"","outlined":"","height":"150"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})]}}],null,true)}),_c('v-btn',{attrs:{"large":"","block":"","loading":_vm.loading,"color":"primary","type":"submit","disabled":invalid}},[_vm._v(_vm._s(_vm.$t('validations.fields.Submit')))])],1)]}}])})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }