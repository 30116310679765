
<template>
  <div class="top-courses">
    <div class="section-title">
      <h3>{{$t('home.TopCourses.TopCourses')}}</h3>
      <!-- <p>{{$t('home.TopCourses.des')}}</p> -->
    </div>

    <v-tabs centered v-model="tab">
     <v-tab v-show="grades.length" 
       v-for="(item,i) in grades" :key="i"
      :href="'#tab-'+i" class="py-4"
      :disabled="!item.courses.length" 
      :class="item.courses.length ? 'd-inline ' : 'd-none'">
      {{ item[$i18n.locale].name }} 

      </v-tab>
   </v-tabs> 
    <v-container>
      <v-tabs-items v-if="grades.length" v-model="tab">
        <v-tab-item v-for="i in Object.keys(grades)" :key="i" :value="'tab-'+i">
          <v-row>
             <v-col md="3" 
             v-for="(item,i) in  grades[i].courses" :key="i">
              <v-card :to="`/course/${item.id}`">
                <v-img height="250" :src="item.image" 
                
                
                  :lazy-src="item.image"
                ></v-img>

                <v-card-text>
                  <div class="text-subtitle-1">{{item.teacher_name}}</div>

                  <div class="black--text text-h6">{{item[$i18n.locale].name}}</div>
                </v-card-text>
                <v-rating
                  :value="item.rate||0"
                  color="orange"
                  background-color="orange lighten-3"
                  readonly
                  size="16"
                ></v-rating>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-icon>mdi-account</v-icon>
                  <span class="subheading" :class="$i18n.locale=='en'?'mr-2':'ml-2'">{{item.no_enrollments}}</span>

                  <v-icon>mdi-comment-account-outline</v-icon>
                  <span class="subheading">{{item.no_comments}}</span>
                </v-card-actions>
              </v-card>
            </v-col>
             <!-- <v-list-item-subtitle class="text-center pa-12"  v-if="tab=='tab-'+i && !grades[i].courses.length" >
                      <span class="subheading">{{$t('courses.contentNotFound')}}</span>
                    </v-list-item-subtitle> -->
                     


            
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
export default {
  computed: {
     grades() {
       return  this.$store.state.global.home.grades;
    },
  },
  data: () => ({
    tab: null,
  }),
  methods:{
  }
};
</script>

<style>
.v-slide-group__content{
  flex: none !important;
  margin: auto;
}
</style>