const loaderOverlay = {
    state: {
        loaderOverlay: false
    },
    mutations: {
        TOGGLE_SHOW_OVERLAY(state) {
            state.loaderOverlay = true;
        },
        TOGGLE_HIDE_OVERLAY(state) {
            state.loaderOverlay = false;
        }
    },
    actions: {
        toggleShowLoaderOverlay({commit}) {
            commit("TOGGLE_SHOW_OVERLAY");
        },
        toggleHideLoaderOverlay({commit}) {
            commit("TOGGLE_HIDE_OVERLAY");
        }
    },
    getters: {
        getloaderOverlay: state => state.loaderOverlay
    }
};
export default loaderOverlay;
