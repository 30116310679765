<template>
  <v-container fill-height="fill-height">
    <v-layout align-center="align-center" justify-center="justify-center">
      <v-flex class="login-form text-xs-center">
        <v-card
          light="light"
          max-width="500"
          elevation="0"
          class="mx-auto"
          :class="{'my-12':$vuetify.breakpoint.mdAndUp}"
        >
          <v-list>
            <v-list-item class="text-center">
              <v-list-item-content>
                <h1 class="auth-title text-h4">{{$t('signUp.RegisterToStartLearning')}}</h1>
                <div class="text-center text-h6" style="color:#6f7074;">
                  {{$t('login.LoginToYourAccount')}}
                  <v-btn text color="#79b530" to="/login" class="px-0 font-weight-medium text-h6">{{$t('header.Login')}}</v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-text class="py-0 px-0">
            <validation-observer ref="observer" v-slot="{ invalid }">
              <form @submit.prevent="submit">
                        <validation-provider
                          v-slot="{ errors }"
                          name="firstName"
                          :rules="{required:true,max:161,min:2,alpha_spaces:true
}"
                        >
                          <v-text-field
                            v-model="signup.first_name"
                            :error-messages="errors"
                            :label="$t('validations.fields.firstName')"
                            type="text"
                            required
                            single-line
                            outlined
                            height="50"
                          ></v-text-field>
                        </validation-provider>
                        <validation-provider
                          v-slot="{ errors }"
                          name="LastName"
                          :rules="{required:true,max:161,min:2,alpha_spaces:true
}"
                        >
                          <v-text-field
                            v-model="signup.last_name"
                            :error-messages="errors"
                            :label="$t('validations.fields.LastName')"
                            type="text"
                            required
                            single-line
                            outlined
                            height="50"
                          ></v-text-field>
                        </validation-provider>
                         <validation-provider
                          v-slot="{ errors }"
                          name="phoneNumber"
                          rules="required|mobileregex"
                        >
                          <v-text-field
                            v-model="signup.mobile"
                            :error-messages="errors"
                            :label="$t('validations.fields.phoneNumber')"
                            type="text"
                            required
                            single-line
                            outlined
                            height="50"
                          ></v-text-field>
                        </validation-provider>
                          <validation-provider v-slot="{ errors }" name="YourGrade" rules="required">
                          <v-select
                        
                            v-model="signup.grade_id"
                            :error-messages="errors"
                            :items="grades"
                            :item-text="grades => grades[$i18n.locale].name"
                            item-value="id"
                            :label="$t('validations.fields.YourGrade')"
                            type="text"
                            required
                            single-line
                            outlined
                            height="50"
                          ></v-select>
                        </validation-provider>
                          <v-alert dense text type="error" v-show="isExist == true">Email Already Exists</v-alert>
                        <validation-provider
                          v-slot="{ errors}"
                          name="email"
                          rules="required|email"
                        >
                       
                          <v-text-field
                            v-model="signup.email"
                            :error-messages="errors "
                            :label="$t('validations.fields.email')"
                            type="email"
                            required
                            single-line
                            outlined
                            @keyup="checkEmail()"
                            @blur="()=>{signup.email=signup.email.toLowerCase()}"
                            height="50"
                          ></v-text-field>
                        </validation-provider>
                  
                         
                      
                       
                     
                        <validation-provider
                          v-slot="{ errors }"
                          name="password"
                          rules="required|min:8|passwordRegex"
                        >
                          <v-text-field
                            v-model="signup.password"
                            :type="show1 ? 'text' : 'password'"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show1 = !show1"
                            @click.right.prevent 
                            @copy.prevent 
                            @paste.prevent
                            :error-messages="errors"
                            :label="$t('validations.fields.password')"
                            required
                            single-line
                            outlined
                            height="50"
                          ></v-text-field>
                        </validation-provider>
                       
                      <v-btn
                         large
                         block
                        :loading="loading"
                        color="primary"
                        class="my-4"
                        type="submit"
                        :disabled="invalid || (isExist == true)"
                      >{{$t('signUp.Register')}}</v-btn>
                      
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import coursesModule from "@/store/modules/courses";

export default {

  data: () => ({
  
    signup: {
      first_name: "",
      last_name: "",
      mobile: "",
      grade_id: "",
      email: "",
      password: ""
    },
    invalidData: false,
    loading: false,
    show1: false,
    isExist: ""
  }),
  
  computed: {
    grades() {
      return this.$store.state.courses.grades;
    },
  },
  mounted() {
    this.getGrades();
  },
  methods: {
    trimInput(v) {
      if (v) {
        let removedSpaces = v.trim();
        v !== removedSpaces;
      }
      return null;
    },
    getGrades() {
      this.$store.dispatch("courses/getGrades");
    },
    checkEmail() {
      this.$store
        .dispatch("auth/checkEmail", {email:this.signup.email})
        .then(res=>{
          if(res.data.exists == true){
            this.isExist = true
          }else{
            this.isExist = false
          }
       
          // this.verfied=true
        }).catch(err=>{
        })
    },
    submit() {
      this.loading = true;
      this.$store
        .dispatch("auth/signUp", this.signup)
        .then((res) => {
          this.loading = false;
          this.$router.push("/login");
      
        })
        .catch((err) => {
          //  if (err.errors.email) {
          //   this.invalidData = err.errors.email;
          // }
          this.invalidData = Object.entries(err.errors).forEach(
            ([key, value]) => {
            }
          );
          this.loading = false;
        });
    },
  },
  created() {
    if (!coursesModule.isRegistered) {
      this.$store.registerModule("courses", coursesModule);
      coursesModule.isRegistered = true;
    }
  },
};
</script>

<style>
.auth-title {
  font-size: 25px !important;
  font-weight: bold !important;
}
</style>