<template>
  <div>
    <Breadcrumb :lecture="lecture" />

    <NotLoggedInScreen 
    v-if="!isSubscriped"
    :lecture="lecture" 
    :isSubscriped="isSubscriped" 
    :buyed="buyed" 
    :readyToEnroll="readyToEnroll" />

    <v-container>

    <lectureContent v-if="isSubscriped && isLoggining" :lecture="lecture" />
    <v-row>
      <v-col md="8" offset="2">
        <Reviews />
      </v-col>
    </v-row>
    
    </v-container>
  </div>
</template>

<script>
import lecturesModule from "@/store/modules/lectures";
import { mapGetters } from "vuex";
import NotLoggedInScreen from "./components/not-loggedin-screen/not-loggedin-screen.vue";
import Reviews from "./components/reviews/reviews.vue";
import Breadcrumb from "./components/breadcrumb/breadcrumb.vue";
import lectureContent from "./components/content/lectureContent.vue";

export default {
  data() {
    return {
      lectureId: null,
      lecture: {
        ar: { name: "", description: "" },
        en: { name: "", description: "" },
        id: null,
        is_checkout: false,
        no_students: 0,
        price: 0,
        rate: { total_rate: 0, total_no_rate: 0 },
        total_no_rate: 0,
        total_rate: 0,
        subscription: null,
        total_no_comments: 0,
      },
      loading: false,
      readyToEnroll: false,
      buyed: false,
      isSubscriped: false
    };
  },
  components: {
    NotLoggedInScreen,
    Reviews,
    Breadcrumb,
    lectureContent
  },
  computed: {
    ...mapGetters({
      isLoggining: "auth/isLoggedIn",
    }),
  },
  created() {
    if (!lecturesModule.isRegistered) {
      this.$store.registerModule("lectures", lecturesModule);
      lecturesModule.isRegistered = true;
    }
    this.lectureId = this.$route.params.id;
    this.getLecture(this.lectureId);

  },
  methods: {
    async getLecture(lectureId) {
      await this.$store
        .dispatch("lectures/getLecturesById", lectureId)
        .then((res) => {
          this.lecture = res;

          this.isSubscriped = this.lecture.subscription != null;
         
          this.readyToEnroll = (this.lecture.subscription == null && this.lecture.price == 0) ||
          (this.lecture.price > 0 &&
            this.lecture.is_checkout == true &&
            this.lecture.subscription == null);

          this.buyed = this.lecture.price > 0 &&
            this.lecture.is_checkout == false &&
            this.lecture.subscription == null;
   
        })
        .catch((err) => {});
    },
    // enroll() {
    //   this.loading = true;
    //   if (!this.isLoggining) {
    //     this.$router.push("/login");
    //   } else {
    //     const body = {
    //       lecture_id: this.lectureId,
    //     };
    //     this.$store
    //       .dispatch("lectures/enrollment", body)
    //       .then(() => {
    //         this.getLecture(this.lectureId);
    //       })
    //       .catch(() => {});
    //   }
    // },
    // buy() {
    //   localStorage.setItem("7asety_lectureId", this.$route.params.id);
    //   if (!this.isLoggining) {
    //     this.$router.push("/login");
    //   } else {
    //   this.$router.push("/checkout");
    //   }
    // },
  },
};
</script>

<style lang="scss" >
.v-tabs--vertical > .v-tabs-bar .v-tabs-bar__content {
  align-items: flex-start !important;
}
.lectures {
  .v-expansion-panel--active:not(:first-child),
  .v-expansion-panel--active + .v-expansion-panel {
    margin-top: 0 !important;
  }
  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
}
</style>
