import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import router from './router'
import store from './store';
import './common/pipes';
import VueAnalytics from 'vue-analytics';

// Styles: SCSS
import "./assets/scss/main.scss";
import './plugins';
Vue.config.productionTip = false


Vue.use(VueAnalytics, {
   id: 'G-PWMDFE00T7',
   router
})

new Vue({
  vuetify,
  i18n,
  router,
  store,
  
  render: h => h(App)
}).$mount('#app')
