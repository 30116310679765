import Vue from 'vue'
import Snackbar from './helpers/snackbar/snackbar'
import Alert from './helpers/alert/alert'
import LoaderOVerlay from './helpers/loaderoverlay/loaderoverlay'
import AuthModule from './modules/auth'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth:AuthModule,
    LoaderOVerlay,
    Snackbar,
    Alert
  }
})
