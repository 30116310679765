import exams from "../../../services/exam";

export default {

    getExam({ commit },id) {
      return new Promise((resolve, reject) => {
        exams
          .getExam(id)
          .then((resp) => {
            
              commit("SHOW_EXAMS",  resp.response.data);
              resolve( resp.response.data);
            
          })
          .catch((err) => {
         
            return reject(err);
          });
      });
    },
   async createExam({ commit },data) {
      return new Promise((resolve, reject) => {
        exams
          .createExam(data.id,data.type)
          .then((resp) => {
            
              commit("SET_EXAMS",  resp.response.data);
              resolve( resp.response.data);
            
          })
          .catch((err) => {
         
            return reject(err);
          });
      });
    },
    saveAnswer({ commit },data) {
      return new Promise((resolve, reject) => {
        exams
          .saveAnswer(data)
          .then((resp) => {
            
              resolve( resp.response.data);
            
          })
          .catch((err) => {
         
            return reject(err);
          });
      });
    },    
    submitExam({ commit },data) {
      return new Promise((resolve, reject) => {
        exams
          .submitExam(data)
          .then((resp) => {
            
              resolve( resp.response.data);
            
          })
          .catch((err) => {
         
            return reject(err);
          });
      });
    },

  
  
   
  };