
<template>
  <div class="whatwedo">
    <v-container>
      <h1>{{$t('home.WhatWeDo.WhatWeDo')}}</h1>
      <span class="sub">{{$t('home.WhatWeDo.title')}}</span>
    
      <div class="mt-8">
        <v-row :no-gutters="!$vuetify.breakpoint.mdAndDown" justify="center">

          <v-col md="3">
            <img
              height="80"
              width="160"
              class="mx-auto"
              src="../../../../assets/images/visa-colored.png" 
            >
          </v-col>
          <v-col md="3">
            <img
              height="80"
              width="160"
              class="mx-auto"
              src="../../../../assets/images/mastercard.png" 
            >
          </v-col>

          <v-col md="3">
            <div class="text-center">
              <img
              height="80"
              width="160"
              class="mx-auto"
              src="../../../../assets/images/fawry.png"
            >
            </div>
            
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>