<template>
    <v-container class="exam my-12" @click.right.prevent  @copy.prevent >
          <div class="row grey lighten-4">
            <div class="col-4">
              <p>
                Time:
                <span class="text-subtitle-1 font-weight-bold">
                   {{exam.exam_minutes}} Mins
               </span>
              </p>
            </div>
            <div class="col-4">
               <p>
                Start Time:
                <span class="text-subtitle-1 font-weight-bold ">
                   {{exam.started_at |formatDateTime('M-D-Y, h:mm a')}}
                  </span>
              </p>
            </div>
            <div class="col-4">
               <p>
                End Time:
                <span class="text-subtitle-1 font-weight-bold">
                   {{exam.ending_at |formatDateTime('M-D-Y, h:mm a')}}
                  </span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
               <v-tabs-items v-model="tab" >
                     <v-tab-item v-for="(question, i) in  questions" :key="i">
                        <h4 class="" v-html="i+1 +'- '+question.question"></h4>
                        <img v-if="question.image != null" :src="question.image" width="200px" height="200px">
                        <v-radio-group v-if="question.type == 'choose'" v-model="question.answer_id">
                           <v-radio class="mb-3"
                           v-for="(answer,i) in question.answers" :key="i"
                           :label="answer.answer"
                           :value="answer.id"
                           ></v-radio>
                        </v-radio-group>
                        <v-textarea v-if="question.type == 'essay'" label="Your answer" v-model="question.essay_answer" ></v-textarea>
                  </v-tab-item>
               </v-tabs-items>
               <div>
                  <v-btn @click="prevQuestion" :disabled="tab == 0">Previous Question</v-btn>
                  <v-btn class="float-right" @click="nextQuestion()" v-if="tab != noQuestions() - 1" >Next Question</v-btn>
                  <v-btn class="float-right" v-if="tab == noQuestions() - 1" @click="confirmSubmitDialogShow = true"> Submit your exam</v-btn>
               </div>
               
            </div>
            <div class="col-md-4">
              <v-row>
                <v-col md="3" v-for="(question, i) in  questions" :key="i">
                  <v-btn @click="tab = i, question.status = true" :color="tabColor(i, question)" >{{ `${i+1}` }}</v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
            <v-dialog v-model="confirmSubmitDialogShow" scrollable max-width="600px">
              <v-card class="text-center">
                <v-card-title class="text-h5">Are you sure you want to submit your answers?</v-card-title>

                <v-card-actions class="mx-auto">
                  <v-spacer></v-spacer>
                  <v-btn color="light" @click="confirmSubmitDialogShow=false">cancel</v-btn>
                  <v-btn color="error" @click="submitExam()">confirm</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
  </v-container>
</template>

<script>
import examModule from "@/store/modules/exam";

export default {
  data() {
    return {
      contentId: null,
      exam: {},
      questions: [],
      tab: 0,
      answers : [],
      confirmSubmitDialogShow: false
    };
  },
  created() {
    if (!examModule.isRegistered) {
      this.$store.registerModule("exams", examModule);
      examModule.isRegistered = true;
    }
    if ((this.contentId = this.$route.params.id)) {
      this.getExam(this.contentId);
    }
  },
  methods: {
    getExam(id) {
      this.$store.dispatch("exams/getExam", id).then((res) => {
        this.exam = res;
        this.questions = res.questions;

      });
    },
    noQuestions() {
      return this.questions.length;
    },
    nextQuestion() {
        this.questions[this.tab].status = true;
       this.tab = this.tab + 1;
    },
    prevQuestion() {
       this.tab = this.tab - 1;
    },
    submitExam() {
      let answers = this.questions.map((question) => {
          return {
            question_id: question.id,
            answer_id: question.answer_id ?? null,
            essay_answer: question.essay_answer ?? null
          }
        });
      let data = {
        id : this.contentId,
        body : {answers : answers}
      }
      this.$store.dispatch("exams/submitExam", data).then((res)=>{
        this.confirmSubmitDialogShow = false;
        this.$router.push(`/lecture/${res.lecture_id}`);
      });
    },
    tabColor(i, question){
      let color = '';

      if(question.status == true){
        color = 'warning';
      }
      if(question.answer_id != null || question.essay_answer != null){
        color = 'success';
      }

       if(i == this.tab){
        color = 'primary';
      }

      return color;
    }
  },
};
</script>