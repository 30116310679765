
<template>
  <div class="categories">
     <v-container>
      <div class="section-title">
        <h3>{{$t('home.Categories.Subjects')}}</h3>
        <!-- <p>{{$t('home.Categories.des')}}</p> -->
      </div>
      <v-row>
        <v-col md="2" cols="12" v-for="(item, index) in categories" :key="`${index}categories`">
          <v-card :to="'teachers?page=1&subject='+item.id"  height="140"  class="text-center d-flex">
            <img
              :src="item.image"
              :lazy-src="item.image"
              width= "100%"
              height="100%"
              style="filter: brightness(60%);"
            >
            <div class="ma-auto bg-text" >
              <v-card-title class="white--text justify-center text-uppercase">
              {{item[$i18n.locale].name}}
            </v-card-title>
            <v-card-subtitle class="white--text subject-subtitle">
              {{$t('home.Categories.Over')}} 
              {{item.number_of_courses}}
              {{$t('home.Categories.Courses with')}} 
              {{item.number_of_teachers}} 
              {{$t('home.Categories.Teachers')}}
            </v-card-subtitle>
            </div>
            
          </v-card>
        </v-col>
      </v-row>
     </v-container>
  </div>
</template>

<script>
export default {
  data:()=>({
   
  }),
  computed:{
    categories(){
      return this.$store.state.global.home.subjects;

    }
  }

}
</script>

<style>

</style>