<template>
  <div>
    <div class="breadcrumb breadcrumb__custom" :style="{'height':'auto'}">
      <v-container>
        <v-breadcrumbs :items="breadcrumb">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :to="item.to" :disabled="item.disabled">{{ $t(item.text) }}</v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <h3>{{singleCourse[$i18n.locale].name}}</h3>

        <v-card-text class="pl-0">
          <v-row align="center" class="mx-0">
            <!-- <v-chip
              color="black"
              class="white--text"
              :class="$i18n.locale == 'en'?'mr-2':'ml-2'"
              filter
              label
            >{{$t('courses.BestSeller')}}</v-chip> -->
            <v-rating
              :value="singleCourse.review.rate ?singleCourse.review.rate  : 0"
              background-color="orange lighten-3"
              color="orange"
              readonly
              size="20"
            ></v-rating>

            <div class="white--text mx-4">
              {{singleCourse.review.rate || 0}} ({{singleCourse.review.rates || 0}} {{$t('courses.Rating')}})
              <!-- NUMBER OF RATING -->
            </div>
            <v-btn color="black" class="ma-2 white--text">
              <v-icon left dark>mdi-share-variant-outline</v-icon>
              {{$t('courses.Share')}}
            </v-btn>
          </v-row>

          <v-row align="center" class="mx-0">
            <div :class="$i18n.locale=='en'?'mr-4':'ml-4'" class="my-4 text-subtitle-1 white--text">
              <v-icon color="white">mdi-download-outline</v-icon>
              {{singleCourse.review.no_enrollments ||0}} {{$t('courses.studentsenrolled')}}
            </div>
            <div class="my-4 text-subtitle-1 white--text">
              <v-icon color="white">mdi-comment-account-outline</v-icon>
              {{singleCourse.review.no_comments || 0}} {{$t('courses.Review')}}
            </div>
          </v-row>
        </v-card-text>
      </v-container>
    </div>
    <v-container fill-height="fill-height" class="single-course">
      <v-layout class="courses">
        <v-row :class="{'my-12':$vuetify.breakpoint.mdAndUp}">
          <v-col md="8">
            <div class="lectures mb-5">
              <span class="text-left text-h6">{{$t('courses.Lectures')}}</span>
              <!-- LECTURES START -->
              <v-expansion-panels multiple>
                <v-expansion-panel v-for="(item,i) in singleCourse.lectures" :key="i">
                  <v-expansion-panel-header class="px-5"  color="#edeff7">
                    <template v-slot:actions>
                      <v-icon color="">
                        <!-- mdi-chevron-down -->
                      </v-icon>
                    </template>
                    <template v-slot:default="{  }">
                      <v-row no-gutters>
                        <v-col lg="2" md="3"  cols="3">
                          <p>{{item[$i18n.locale].name}}</p>
                        </v-col>
                        <v-col lg="2" md="3"  cols="3">
                          <v-chip
                          :class="$i18n.locale == 'en'?'ml-2':'mr-2'"
                            color="red"
                            class="white--text"
                            filter
                            label
                            small
                          >{{item.price||$t('courses.Free')}}</v-chip>
                        </v-col>
                        <v-col lg="8" md="6"  cols="6" :class="$i18n.locale == 'en'?'text-right':'text-left'">
                          <v-btn rounded color="primary" dark :to="`/lecture/${item.id}`" >{{$t('courses.Open')}}</v-btn>
                        </v-col>
                        <v-col cols="12">
                          <p class="mt-3 warm_gray--text" >{{item[$i18n.locale].description}}</p>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <span v-for="(content,i) in item.contents" :key="`${i}content`">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle>
                              <v-icon>{{checkTypeOfContent(content.type)}}</v-icon>
                              <span
                                class="subheading"
                                :class="$i18n.locale=='en'?'ml-2':'mr-2'"
                              >{{content[$i18n.locale].name || '0'}}</span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider v-if="!item.contents.slice(-1)"></v-divider>
                      </span>
                      <span v-if="item.contents&&!item.contents.length">
                        <v-list-item class="text-center">
                          <v-list-item-content>
                            <v-list-item-subtitle>
                              <span class="subheading">{{$t('courses.contentNotFound')}}</span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </span>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <span v-if="!singleCourse.lectures.length">
                <v-list-item class="text-center">
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <span class="subheading">{{$t('courses.contentNotFound')}}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </span>

              <!-- LECTURES END -->
            </div>
            <div class="mb-5">
              <span class="text-left text-h5">{{$t('courses.RelatedCourse')}}</span>
              <v-carousel hide-delimiter-background :show-arrows="singleCourse.related_course.length >3 && $vuetify.breakpoint.mdAndUp || singleCourse.related_course.length >1 && !$vuetify.breakpoint.mdAndUp">
              <template v-for="(item, index) of singleCourse.related_course">
               <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" :key="index">
                
                <v-row>
                 <template v-for="(n,i) in columns">
                <template v-if="(+index + i) < singleCourse.related_course.length">
                <v-col
                  md="4"
                  sm="5"
                  cols="12"
                  :key="i"
                  
                >
                  <v-card :to="`/course/${item.id}`">
                    <img height="220" width="100%" :src="singleCourse.image">

                    <v-card-text>
                      <div class="text-subtitle-1">{{item[$i18n.locale].name}}</div>

                      <div>{{item[$i18n.locale].description}}</div>
                    </v-card-text>

                    <v-card-actions>
                      <v-icon>mdi-account</v-icon>
                      <span
                        class="subheading"
                        :class="$i18n.locale=='en'?'mr-2':'ml-2'"
                      >{{singleCourse.no_enrollments || '0'}}</span>

                      <v-icon>mdi-comment-account-outline</v-icon>
                      <span class="subheading">{{singleCourse.no_comments ||'0'}}</span>
                    </v-card-actions>
                  </v-card>
                </v-col>
                </template>
                 </template>
              </v-row>
               </v-carousel-item>
              </template>
              </v-carousel>
            </div>
          </v-col>
          <v-divider vertical v-if="$vuetify.breakpoint.mdAndUp"></v-divider>

          <v-col md="4" cols="12">
            <v-card class="mx-auto" max-width="300">
              <v-img :src="singleCourse.image" height="200px"></v-img>

              <v-card-subtitle>{{singleCourse[$i18n.locale].description}}</v-card-subtitle>
            </v-card>

            <v-list>
              <span class="text-h6" v-text="$t('courses.CourseFeatures')"></span>

              <span>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{$t('courses.Exams')}}
                      <span
                        :class="$i18n.locale=='en'?'float-right':'float-left'"
                      >{{singleCourse.total_numbers.exam ||0}}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </span>
              <span>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{$t('courses.Video')}}
                      <span
                        :class="$i18n.locale=='en'?'float-right':'float-left'"
                      >{{singleCourse.total_numbers.video ||0}}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </span>
              <span>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{$t('courses.Files')}}
                      <span
                        :class="$i18n.locale=='en'?'float-right':'float-left'"
                      >{{singleCourse.total_numbers.file ||0}}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
              </span>
            </v-list>
              <span class="text-h6" v-text="$t('courses.Teacher')"></span>

            <v-card class="mx-auto text-center" max-width="344" elevation="0">

              <v-spacer></v-spacer>
              <v-list-item-title class="text-h6 pb-2">{{singleCourse.teacher[$i18n.locale].name}}</v-list-item-title>
              <v-spacer></v-spacer>

              <v-avatar size="56">
                <v-img alt="user" :src="singleCourse.teacher.image"></v-img>
              </v-avatar>
              <v-rating
                :value="singleCourse.teacher.rate || 0"
                color="orange"
                background-color="orange lighten-3"
                readonly
                size="20"
              ></v-rating>

              <v-card-subtitle>{{singleCourse.teacher[$i18n.locale].description || $t('courses.contentNotFound')}}</v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import coursesModule from "@/store/modules/courses";

import BreadCrumb from "../../../components/breadcrumb/Breadcrumb.vue";
export default {
  data: () => ({
    loader: false,
    breadcrumb: [
      { text: "header.Home", to: "/home", disabled: false },
      { text: "header.AllCourses", to: "/courses", disabled: false },
    ],
  }),
  components: {
    BreadCrumb,
  },
   watch: {
    $route(val) {
      if(val.params.id){
        this.getCourseByID(val.params.id)
      }
    },
   },
  computed: {
    singleCourse() {
      return this.$store.state.courses.singleCourse;
    },
      columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 3;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }

      if (this.$vuetify.breakpoint.md) {
        return 3;
      }
       if (this.$vuetify.breakpoint.sm) {
        return 1;
      }

      return 1;
    },
  },
  created() {
    if (!coursesModule.isRegistered) {
      this.$store.registerModule("courses", coursesModule);
      coursesModule.isRegistered = true;
    }
    if (this.$route.params.id) {
      this.getCourseByID(this.$route.params.id);
    }
  },
  methods: {
    getCourseByID(id) {
      this.loader = "loading";
      this.$store
        .dispatch("courses/getCourseByID", id)
        .then((res) => {
          this.loader = "success";
          document.title= `${this.$i18n.t('titlePage.AllCourses')}| ${res.name}`;
        })
        .catch(() => {
          this.loader = "error";
        });
    },
    checkTypeOfContent(type) {
      if (type == "file") {
        return "mdi-clipboard-file-outline";
      } else if (type == "video") {
        return "mdi-file-video-outline";
      } else {
        return "mdi-file-clock-outline";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.lectures {
  .v-expansion-panel--active:not(:first-child),
  .v-expansion-panel--active + .v-expansion-panel {
    margin-top: 0 !important;
  }
  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
}
.single-course{
  .layout{
    display: block;
  }
}

</style>
