<template>
   <div>
     <h3 class="mb-3 mt-2">{{ $t("exams.submit", {percent : exam.percentage}) }}</h3>
      <v-card elevation="2" outlined shaped>
         <v-row>
            <v-col cols="9">
                <v-card-title>
                  {{ content[$i18n.locale].name }}
               </v-card-title>
               <v-card-subtitle v-html="content[$i18n.locale].description"></v-card-subtitle>
               <div class="justify-content-center mb-3 mx-3">
                  <v-btn v-if="(exam.start_at == null) || canRepeat()" @click="startExam" large rounded color="primary">{{$t('Buttons.Start')}}</v-btn>
                  <!-- <v-btn v-if="canRepeat()" @click="startExam" large rounded color="primary">{{$t('Buttons.Re-exam')}}</v-btn> -->
                  <v-btn v-if="exam.finished_at == null && exam.start_at != null" large rounded color="primary" :to="'/exam/'+content.id" >{{$t('Buttons.Resume')}}</v-btn>
               </div>
           
            </v-col>
            <v-col cols="3" class="text-center exam-grade" >
               
               <h3 class="mt-3 mb-2">{{ $t("exams.grade") }}</h3>
               <p v-if="exam.grade != null">{{ exam.grade }}</p>
               <div class="mb-2 text-h6">
                <span :class="exam.status == 'failed' ? 'error--text':'success--text'">{{exam.user_grade}} </span>
                <span>/ {{exam.exam_grade}}</span>
               </div>
               <v-chip v-if="exam.status == 'failed'" class="d-block mx-auto mb-2" style="width:50%" color="red" dark> Failed </v-chip>
               <v-chip v-if="exam.status == 'success'" class="d-block mx-auto mb-2" style="width:50%" color="green" dark > Passed </v-chip>
            </v-col>
         </v-row>
        
      </v-card>
   </div>
</template>

<script>
import examModule from "@/store/modules/exam";
export default {
  props: ["content"],
  data() {
    return {
      exam: {}
    }
  },
  created() {
    if (!examModule.isRegistered) {
      this.$store.registerModule("exams", examModule);
      examModule.isRegistered = true;
    }
    this.exam = this.content.exam;
  },
  methods: {
    startExam() {
      if(this.exam.start_at == null || this.canRepeat()){
          this.$store.dispatch("exams/createExam", {id: this.content.id, type: "content"}).then((res)=>{
            this.$router.push("/exam/"+this.content.id);
          });
      }else{
        // this.$router.push("/exam/"+this.content.id);
      }
    },
    canRepeat() {
      if(this.exam.status == "success" || this.exam.status == "pending"){
        return false;
      }
      return this.exam.no_repeats == null || (this.exam.no_user_repeats <= this.exam.no_repeats);
    }
  }
};
</script>

<style>
.exam-grade {
   border-left: solid 1px rgba(0, 0, 0, 0.12);
   padding: 16px;
}

.rtl .exam-grade{
   border-right: solid 1px rgba(0, 0, 0, 0.12);
}
/* .grade-line {
  position: absolute;
  min-height: 80px;
  right: 142px;
  transform: translate(52%, 0);
  border: 1px solid;
} */
</style>