<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      :right="$i18n.locale == 'ar'"
      class="sidebar"
      v-if="!$vuetify.breakpoint.mdAndUp"
    >
      <template v-slot:prepend v-if="isLoggining">
        <v-list-item to="/profile/student-info" two-line>
          <v-list-item-avatar>
            <v-avatar size="40" color="black">
              <span class="white--text">{{
                (userInfo.first_name + " " + userInfo.last_name) | short_hand
              }}</span>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="links">{{
              userInfo.first_name + " " + userInfo.last_name
            }}</v-list-item-title>
            <v-list-item-subtitle class="subtitle">{{
              userInfo.email
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item active-class="primary--text text--accent-4" to="/home">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="links">{{
              $t("header.Home")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item active-class="primary--text text--accent-4" to="/courses">
          <v-list-item-icon>
            <v-icon>mdi-cast-education</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="links">{{
              $t("header.Courses")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item active-class="primary--text text--accent-4" to="/teachers">
          <v-list-item-icon>
            <v-icon>mdi-book-alphabet</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="links">{{
              $t("header.Teachers")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          active-class="primary--text text--accent-4"
          to="/contactus"
        >
          <v-list-item-icon>
            <v-icon>mdi-content-save-edit</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="links">{{
              $t("header.ContactUs")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          active-class="primary--text text--accent-4"
          to="/login"
          v-if="!isLoggining"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-group-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="links">{{
              $t("header.Login")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          active-class="primary--text text--accent-4"
          to="/register"
          v-if="!isLoggining"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-group-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="links">{{
              $t("header.Register")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="isLoggining" @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-title class="links">{{
            $t("header.Logout")
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-btn-toggle
        class="sidbar__lang"
        tile
        color="primary accent-3"
        group
        @change="$i18n.locale == 'en' ? changeRTL() : changeLTR()"
      >
        <v-btn
          text
          :class="$i18n.locale == 'en' ? 'v-item--active v-btn--active' : ''"
          >English</v-btn
        >

        <v-btn
          :class="$i18n.locale == 'ar' ? 'v-item--active v-btn--active' : ''"
          text
          >العربيه</v-btn
        >
      </v-btn-toggle>
    </v-navigation-drawer>
    <v-app-bar class="navigation__bar" color="white" dense app height="70">
      <v-app-bar-nav-icon
        class="primary white--text"
        v-if="$vuetify.breakpoint.mdAndDown && searching"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-items v-if="searching || $vuetify.breakpoint.mdAndUp">
        <v-btn text to="/">
          <v-avatar width="120">
            <img
              v-if="$i18n.locale == 'en'"
              src="../../assets/images/7esty blUE@3x.png"
              alt
            />
            <img
              v-if="$i18n.locale == 'ar'"
              src="../../assets/images/7esty blUE ARABIC@3x.png"
              alt
            />
          </v-avatar>
        </v-btn>
      </v-toolbar-items>

      <!-- SEARCH RESPONSIVE -->
      <v-spacer v-if="!$vuetify.breakpoint.mdAndUp"></v-spacer>

      <v-toolbar-items v-if="!$vuetify.breakpoint.mdAndUp">
        <v-expand-transition>
          <v-card
            flat
            v-show="!searching"
            class="my-auto"
            height="auto"
            width="85vw"
          >
            <v-text-field
              width="100"
              :label="$t('header.Search')"
              single-line
              clearable
              class="align-center"
            ></v-text-field>
          </v-card>
        </v-expand-transition>
        <v-btn
          icon
          color="black"
          @click="searching = !searching"
          class="search-visibility"
        >
          <v-icon>{{ searching ? "mdi-magnify" : "mdi-close" }}</v-icon>
        </v-btn>
      </v-toolbar-items>

      <!-- START SEARCH -->
      <v-autocomplete
        class="custom-placeholer-color hidden-sm-and-down search-visibility"
        :label="$t('header.Search')"
        single-line
        background-color="#edeff7"
        rounded
        v-model="search"
        height="45"
        dense
        solo
        flat
        :loading="loading"
        item-text="name"
        item-value="id"
      >
        <!-- :items="generalSearch"
       :search-input.sync="items" -->
        <template v-slot:append>
          <v-btn icon color="#000000">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
      </v-autocomplete>

      <!-- END SEARCH -->

      <v-toolbar-items class="hidden-sm-and-down mr-5">
        <v-btn text color="primary" to="/courses">{{
          $t("header.Courses")
        }}</v-btn>
        <v-btn text color="primary" to="/teachers">{{
          $t("header.Teachers")
        }}</v-btn>
        <v-btn text color="primary" to="/contactus">{{
          $t("header.ContactUs")
        }}</v-btn>
      </v-toolbar-items>

      <v-toolbar-items v-if="searching || $vuetify.breakpoint.mdAndUp">
        <v-menu v-if="isLoggining" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text color="primary" class="px-8">
              <v-avatar
                v-bind="attrs"
                v-on="on"
                style="overflow: visible !important"
              >
                {{ $t("header.Mylearning") }}
              </v-avatar>
            </v-btn>
          </template>
          <v-list>
            <span
              v-for="(item, index) in subscriptions"
              :key="`${index}learning`"
            >
              <v-list-item class="grow" :to="`/lecture/${item.lecture.id}`">
                <!-- <v-list-item-avatar tile size="90">
                  <img :src="item.lecture.image" alt="John" />
                </v-list-item-avatar> -->

                <v-list-item-content>
                  <v-list-item-title>{{ item.lecture.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>
            </span>
            <span v-if="subscriptions && subscriptions.length ? false : true">
              <v-list-item class="text-center">
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <span class="subheading">{{
                      $t("courses.contentNotFound")
                    }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </span>
          </v-list>
        </v-menu>
      </v-toolbar-items>

      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn v-if="!isLoggining" text color="#0a0a0a" to="/login">
          <v-icon>mdi-account</v-icon>
          {{ $t("header.Login") }}/ {{ $t("header.Register") }}
        </v-btn>

        <v-menu v-if="isLoggining" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text>
              <v-avatar
                v-bind="attrs"
                color="black"
                class="mx-auto"
                size="35px"
                v-on="on"
              >
                <span class="white--text text-h6 text-center">{{
                  (userInfo.first_name + " " + userInfo.last_name) | short_hand
                }}</span>
              </v-avatar>
            </v-btn>
          </template>
          <v-list>
            <v-list-item class="grow">
              <v-list-item-avatar>
                <v-avatar size="35" color="black">
                  <span class="white--text">{{
                    (userInfo.first_name + " " + userInfo.last_name)
                      | short_hand
                  }}</span>
                </v-avatar>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{
                  userInfo.first_name + " " + userInfo.last_name
                }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  userInfo.email
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item
              v-for="(item, index) in settings"
              :key="index"
              :to="item.to"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>

              <v-list-item-title>{{ $t("header.Logout") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          text
          color="#0a0a0a"
          @click="$i18n.locale == 'en' ? changeRTL() : changeLTR()"
        >
          <v-icon>mdi-earth</v-icon>
          {{ currentLang }}
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { localeChanged } from "vee-validate";
import profileModule from "@/store/modules/profile";
import globalModule from "@/store/modules/global";

export default {
  data: () => ({
    drawer: false,
    group: null,
    fab: false,
    searching: true,
    search: "",
    loader: null,
    loading: false,
    items: [],
    myLearning: [
      {
        image: "https://cdn.vuetifyjs.com/images/john.jpg",
        title: "Angular",
      },
      {
        image: "https://cdn.vuetifyjs.com/images/john.jpg",
        title: "Vue",
      },
      {
        image: "https://cdn.vuetifyjs.com/images/john.jpg",
        title: "Js",
      },
    ],
    settings: [
      {
        title: "account.MyProfile",
        icon: "mdi-account",
        to: "/profile/student-info",
      },
    ],
  }),

  mounted() {
    if (this.isLoggining) {
      this.getSubscriptions();
    }
  },
  created() {
    if (!profileModule.isRegistered) {
      this.$store.registerModule("profile", profileModule);
      profileModule.isRegistered = true;
    }
    if (!globalModule.isRegistered) {
      this.$store.registerModule("global", globalModule);
      globalModule.isRegistered = true;
    }
    //  if (this.$route.query) {
    //   this.generalSearch(this.$route.query);
    // }
  },
  filters: {
    short_hand(company_name) {
      // You can put your logic here...
      let words = company_name.split(" ");
      let short_hand =
        words[0][0].toUpperCase() + words[words.length - 1][0].toUpperCase();
      return short_hand; // <-- The return value as Per logic
    },
  },

  computed: {
    ...mapGetters({
      isLoggining: "auth/isLoggedIn",
      userInfo: "auth/userData",
    }),
    ...mapState("profile", {
      subscriptions: "subscriptions",
    }),
    currentLang() {
      if (this.$vuetify.rtl) {
        return "English";
      } else {
        return "العربيه";
      }
    },

    getSearch() {
      return this.generalSearch;
    },
  },

  watch: {
    group() {
      this.drawer = false;
    },
    isLoggining(val) {
      if (val) {
        this.getSubscriptions();
      }
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout").then(() => {});
    },
    getSubscriptions() {
      this.$store
        .dispatch("profile/getSubscriptions", "active")
        .then(() => {})
        .catch(() => {});
    },
    changeRTL() {
      this.$vuetify.rtl = true;
      this.setLocale("ar");
      this.direction = "rtl";
      document.body.classList.add("rtl");
      localStorage.setItem("direction", this.direction);
    },
    changeLTR() {
      this.$vuetify.rtl = false;
      this.direction = "ltr";
      document.body.classList.remove("rtl");
      localStorage.setItem("direction", this.direction);
      this.setLocale("en");
    },
    setLocale(local) {
      const msgs = require(`../../locales/${local}.json`);
      this.$i18n.setLocaleMessage(local, msgs);
      this.$i18n.locale = local;
      localeChanged();
      // window.location.reload()
    },
    generalSearch() {
      this.$store
        .dispatch("global/generalSearch", this.search)
        .then((res) => {
          this.items = res;
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.sidebar .links {
  font-size: 1rem;
}
.subtitle {
  font-size: 0.95rem;
}
.search-visibility {
  visibility: hidden;
}
</style>