<template>
  <v-container>
      <v-card
        v-if="!isSubscriped"
        class="mx-auto"
        max-width="600"
        outlined
        :class="{ 'my-12': $vuetify.breakpoint.mdAndUp }"
      >
        <v-card-title>
          {{ lecture[$i18n.locale].name }}
        </v-card-title>

        <v-card-subtitle>
          {{ lecture[$i18n.locale].description }}
        </v-card-subtitle>

        <v-card-text>
          <span class="price">{{
            lecture.price == 0
              ? $t("courses.Free")
              : lecture.price + " " + $t("courses.LE")
          }}</span>
        </v-card-text>
        <LoaderOverlay v-if="show" />
        <v-card-actions v-if="isLoggining">
          <v-btn v-if="readyToEnroll" @click="enroll"> start lecture </v-btn>
          <v-btn v-if="buyed" @click="buy"> Buy </v-btn>
        </v-card-actions>

        <p v-if="!isLoggining" class="text-center">Please login / register to start the lecture</p>

        <v-card-actions v-if="!isLoggining">
          <v-row justify="space-around" class="text-center">
            <v-col md="6">
              <v-btn rounded color="primary" dark to="/login">
                {{ $t("header.Login") }}
              </v-btn>
            </v-col>
            <v-col md="6">
              <v-btn rounded color="primary" dark to="/register">
                {{ $t("header.Register") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

      </v-card>
    </v-container>
</template>

<script>
import lecturesModule from "@/store/modules/lectures";
import { mapGetters } from "vuex";
import LoaderOverlay from "../../../../../components/loader-overlay/LoaderOverlay.vue";

export default {
  props: ['lecture','readyToEnroll', 'buyed', 'isSubscriped'],
  data() {
    return {
      lectureId: null,
      // loading: false,
      // readyToEnroll: false,
      // buyed: false,
      // isSubscriped: false
      show: false
    };
  },
  components: {
    LoaderOverlay
  },
  computed: {
    ...mapGetters({
      isLoggining: "auth/isLoggedIn",
    }),
  },
  created() {
    if (!lecturesModule.isRegistered) {
      this.$store.registerModule("lectures", lecturesModule);
      lecturesModule.isRegistered = true;
    }
    this.lectureId = this.$route.params.id;
  },
  methods: {
    enroll() {
      this.loading = true;
      this.show = true;
      if (!this.isLoggining) {
        this.$router.push("/login");
      } else {
        const body = {
          lecture_id: this.lectureId,
        };
        this.$store
          .dispatch("lectures/enrollment", body)
          .then(() => {
             window.location.reload()
          })
          .catch(() => {});
      }
    },
    buy() {
      localStorage.setItem("7asety_lectureId", this.lectureId);
      if (!this.isLoggining) {
        this.$router.push("/login");
      } else {
      this.$router.push("/checkout");
      }
    },
  },
};
</script>