import endpoints from "../common/endpoints";
import http from "../common/http";
const lectureUrl = endpoints.LECTURES_ENDPOINT;
const reviewsUrl = endpoints.REVIEWS_ENDPOINT;
const reviewUrl = endpoints.REVIEW_ENDPOINT;
const checloutUrl = endpoints.CHECKOUT_ENDPOINT;
const enrollmentUrl = endpoints.ENROLLMENT_ENDPOINT;
const downloadUrl = endpoints.DOWNLOAD_CONTENT_ENDPOINT;
const paymentUrl = endpoints.PAYMENT_ENDPOINT;

export default {
  getLecturesById(id) {
    return http.get(`${lectureUrl}/${id}`).then((res) => {
      return res;
    });
  },
  downloadLectuter(id) {
    return http.get(`${downloadUrl}/${id}`).then((res) => {
      return res;
    });
  },
  reviews(id) {
    return http.get(`${reviewsUrl}/${id}`).then((res) => {
      return res;
    });
  },
  createReview(body) {
    return http.post(`${reviewUrl}/${body.model}/${body.id}`,body.body).then((res) => {
      return res;
    });
  },
  payment(body) {
    return http.post(paymentUrl,body).then((res) => {
      return res;
    });
  },
  enrollment(id) {
    return http.post(enrollmentUrl,id).then((res) => {
      return res;
    });
  },
  getLectureContents(id){
    return http.get(`${lectureUrl}/${id}/contents`).then((res) => {
      return res;
    });
  }
};
