<template>
  <v-container fill-height="fill-height">
    <v-layout align-center="align-center" justify-center="justify-center">
      <v-flex class="login-form text-xs-center">
        <v-card light="light" max-width="500" elevation="0" :class="{'my-12':$vuetify.breakpoint.mdAndUp}" class="mx-auto ">
          <v-list>
            <v-list-item class="text-center">
              <v-list-item-content>
                <h1 class="auth-title">{{$t('becomeTeacher.becomeTitle')}}</h1>
                
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-text>
            <validation-observer ref="observer" v-slot="{ invalid }">
              <form ref="afterSubmit" @submit.prevent="submit">
                   <validation-provider
                          v-slot="{ errors }"
                          name="name"
                          :rules="{required:true,min:3,max:30,alpha_spaces:true
}"
                        >
                          <v-text-field
                            v-model="name"
                            :error-messages="errors"
                            :label= "$t('validations.fields.name')"
                            type="text"
                            required
                            single-line
                            outlined
                            height="50"
                          ></v-text-field>
                        </validation-provider>
                <validation-provider v-slot="{ errors }" name="email" rules="required|email">
                  <v-alert v-show="invalidData.length"
                  :key="index+'dfsdf'"
                  v-for="(err, index) of invalidData" dense outlined type="error">{{err}}</v-alert>
                
                  <v-text-field
                    v-model="email"
                    :error-messages="errors"
                    :label= "$t('validations.fields.email')"
                    type="email"
                    required
                    single-line
                    outlined
                    height="50"
                  ></v-text-field>
                </validation-provider>
                 <validation-provider
                          v-slot="{ errors }"
                          name="phoneNumber"
                          :rules="{required:true,
            mobileregex:true,
          numeric:true
          }"
                        >
                          <v-text-field
                            v-model="mobile"
                            :error-messages="errors"
                            :label= "$t('validations.fields.phoneNumber')"
                            type="text"
                            required
                            single-line
                            outlined
                            height="50"
                          ></v-text-field>
                 </validation-provider>
                      <validation-provider
                          v-slot="{ errors }"
                          name="password"
                          rules="required|min:8|passwordRegex"
                        >
                          <v-text-field
                            v-model="password"
                            :type="show1 ? 'text' : 'password'"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show1 = !show1"
                            :error-messages="errors"
                            :label="$t('validations.fields.password')"
                            required
                            single-line
                            outlined
                            height="50"
                          ></v-text-field>
                        </validation-provider>
                        <validation-provider
                          v-slot="{ errors }"
                          name="ConfirmPassword"
                          rules="required|confirmed:password|min:8"
                        >
                          <v-text-field
                            v-model="c_password"
                            :type="show2 ? 'text' : 'password'"
                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show2 = !show2"
                            :error-messages="errors"
                            :label="$t('validations.fields.ConfirmPassword')"
                            required
                            single-line
                            outlined
                            height="50"
                          ></v-text-field>
                        </validation-provider>
                        <validation-provider v-slot="{ errors }" name="Grade" rules="required">
                          <v-select
                            v-model="grade_id"
                            :error-messages="errors"
                            :items="grades"
                            :item-text="grades => grades[$i18n.locale].name"
                            item-value="id"
                            :label="$t('validations.fields.Grade')"
                            type="text"
                            required
                            multiple
                            single-line
                            outlined
                            height="50"
                          ></v-select>
                        </validation-provider>
                        <validation-provider v-slot="{ errors }" name="subjects" rules="required">
                          <v-select
                            v-model="subject_id"
                            :error-messages="errors"
                            :items="subjects"
                            :item-text="subjects => subjects[$i18n.locale].name"
                            item-value="id"
                            :label="$t('validations.fields.subjects')"
                            type="text"
                            required
                            multiple
                            single-line
                            outlined
                            height="50"
                          ></v-select>
                        </validation-provider>
                <v-btn
                  large
                  block
                  :loading="loading" 
                  color="primary"
                  type="submit"
                  :disabled="invalid"
                >{{$t('validations.fields.Submit')}}</v-btn>
              </form>
              
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import coursesModule from "@/store/modules/courses";
import teachersModule from '@/store/modules/teachers';


export default {
 
  data: () => ({
    
      name: "",
      email: "",
      mobile: "",
      password: "",
      grade_id: "",
      subject_id: "",
      c_password: "",
     invalidData: false,
    loading: false,
    submitting: false,
    show1: false,
    show2: false
  }),

  computed: {
     grades() {
      return this.$store.state.courses.grades;
    },
     subjects() {
      return this.$store.state.courses.subjects;
    },
  },
  mounted() {
    this.getGrades();
     this.getSubjects();
  },
  methods: {
     getGrades() {
      this.$store.dispatch("courses/getGrades");
    },
      getSubjects() {
      this.$store.dispatch("courses/getSubjects")
    },
    submit() {
      // this.$refs.observer.validate();
      this.loading = true;
      this.submitting = true;
    const teacher ={
      name: this.name,
      email: this.email,
      mobile: this.mobile,
      password: this.password,
      c_password: this.c_password,
      grades: this.grade_id,
      subjects: this.subject_id
    };
      this.$store
        .dispatch("teachers/becomeTeacher", teacher)
        .then((res) => {
          this.loading = false;
          this.submitting = false;
         
          // this.$refs.afterSubmit.reset();
         this.name= "";
         this.email= "";
         this.mobile= "";
         this.password= "";
         this.grade_id= "";
         this.subject_id= "";
         this.c_password= "";
         this.$refs.observer.reset();
        })
        .catch((err) => {
          if (err) {
            this.invalidData = err.errors;
            this.loading = false;
          }
        });
    },
  
  },
    created() {
    if (!coursesModule.isRegistered) {
      this.$store.registerModule("courses", coursesModule);
      coursesModule.isRegistered = true;
    }
     if (!teachersModule.isRegistered) {
      this.$store.registerModule("teachers", teachersModule);
      teachersModule.isRegistered = true;
    }
  },
    
};
</script>

<style>
.auth-title {
  font-size: 25px !important;
  font-weight: bold !important;
}
</style>
