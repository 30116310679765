import profile from "../../../services/profile";

export default {

    getProfile({ commit }) {
      return new Promise((resolve, reject) => {
        profile
          .getProfile()
          .then((resp) => {
            
              commit("SET_PROFILE",  resp.response.data);
              resolve( resp.response.data);
            
          })
          .catch((resp) => {
         
            return reject(resp);
          });
      });
    },

    updateProfile({ commit }, data) {
      return new Promise((resolve, reject) => {
        profile
          .updateProfile(data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((res) => {
            return reject(res);
          });
      });
    },

    getPayments({ commit }, page) {
        return new Promise((resolve, reject) => {
          profile
            .getPayments(page)
            .then((resp) => {
              
                commit("SET_PAYMENTS",  resp.response);
                resolve( resp.response.data);
              
            })
            .catch((resp) => {
           
              return reject(resp);
            });
        });
      },

      getSubscriptions({ commit },status ) {
        return new Promise((resolve, reject) => {
          profile
            .getSubscriptions(status)
            .then((resp) => {
                commit("SET_SUBSCRIPTIONS",  resp.response.data);
                resolve( resp.response.data);
              
            })
            .catch((resp) => {
           
              return reject(resp);
            });
        });
      },

      updatePassword({ commit }, data) {
        return new Promise((resolve, reject) => {
          profile
            .updatePassword(data)
            .then((resp) => {
              resolve(resp.response);
            })
            .catch((res) => {
              return reject(res);
            });
        });
      },
  
   
  };