import endpoints from "../common/endpoints";
import http from "../common/http";

const teachersUrl = endpoints.TEACHERS_ENDPOINT;

export default{
  
    becomeTeacher(data) {
        return http.post(teachersUrl, data).then((res)=>{
            return res
        })
    },
    getTeachers(data) {
        return http
          .get(teachersUrl, {
            params: {
              page: data.page,
              subject_id: data.subject_id,
              grade_id: data.grade_id,
              search: data.search,
            },
          })
          .then((res) => {
            return res;
          });
      },

}