 

export default {
  grades:[],
  courses:[],
  singleCourse:{
    ar : {
      name: '',
      description: ''
    },
    en : {
      name: '',
      description: ''
    },
    review: {
      rate: 0
    },
    teacher: {
      rate: 0
    }
  },
  coursesMeta:'',
  subjects:[],
  teachers:[],


}
