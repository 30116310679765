import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/global/home/Home.vue";
import i18n from "../i18n";
/*
*No stacktrace on NavigationDuplicated error uncaught in promise

*If you want to handle this globally, you can replace Router's 
push/replace functions to silence the rejection and make the 
promise resolve with the error instead:
*/
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};


Vue.use(VueRouter);

const routes = [
  // =============================================================================
  // MAIN LAYOUT ROUTES
  // =============================================================================
  { path: '/', component: Home, redirect: '/home' },
  {
    path: "",
    component: () => import("../layouts/main/Main.vue"),
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
        meta: {
          title: "titlePage.Home",
        },
      },
      {
        path: "/success-payment",
        name: "SuccessPayment",
        component: () => import("../views/courses/checkout/components/success-payment/success-payment.vue"),
        meta: {
          title: "titlePage.SuccessPayment",
        },
      },
      {
        path: "/failed-payment",
        name: "FailedPayment",
        component: () => import("../views/courses/checkout/components/error-payment/error-payment.vue"),
        meta: {
          title: "titlePage.FailedPayment",
        },
      },
      {
        path: "/checkout",
        name: "Checkout",
        component: () => import("../views/courses/checkout/components/checkout/checkout.vue"),
        meta: {
          title: "titlePage.Checkout",
          requiresAuth: true 
        }
      },



    ],
  },

  // =============================================================================
  // Auth ROUTES
  // =============================================================================
  {
    path: "",
    component: () => import("../layouts/auth/Auth.vue"),
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("../views/Auth/login/Login.vue"),
        meta: {
          breadcrumb: [
            { text: "header.Home", to: "/", disabled: false },
            { text: "header.Login", to: "/", disabled: true },

          ],
          breadcrumbHeight: "335px",
          title: "titlePage.Login",
          breadcrumbTitle: "header.Login",
        },
      },
      {
        path: "register",
        name: "Register",
        component: () => import("../views/Auth/register/Register.vue"),
        meta: {
          breadcrumb: [
            { text: "header.Home", to: "/", disabled: false },
            { text: "header.Register", to: "/", disabled: true },
          ],
          breadcrumbHeight: "335px",
          title: "titlePage.Register",
          breadcrumbTitle: "header.Register",
        },
      },
      {
        path: "forget-password",
        name: "ForgetPassword",
        component: () => import("../views/Auth/forget-password/ForgetPassword.vue"),
        meta: {
          breadcrumb: [
            { text: "header.Home", to: "/", disabled: false },
            { text: "login.ForgetPassword", to: "/", disabled: true },
          ],
          breadcrumbHeight: "335px",
          title: "titlePage.ForgetPassword",
          breadcrumbTitle: "login.ForgetPassword",
        },
      },
      {
        path: "password/reset",
        name: "ResetPassword",
        component: () => import("../views/Auth/reset-password/ResetPassword.vue"),
        meta: {
          breadcrumb: [
            { text: "header.Home", to: "/", disabled: false },

            { text: "login.ResetPassword", to: "/", disabled: true },
          ],
          breadcrumbHeight: "335px",
          title: "titlePage.ResetPassword",
          breadcrumbTitle: "login.ResetPassword",
        },
      },
      {
        path: "email-sent",
        name: "EmailSentPassword",
        component: () => import("../views/Auth/email-sent/EmailSent.vue"),
        meta: {
          breadcrumb: [
            { text: "header.Home", to: "/", disabled: false },

            { text: "signUp.Register", to: "/register", disabled: false },
            { text: "signUp.Confirm", to: "/", disabled: true },
          ],
          breadcrumbHeight: "335px",
          title: "titlePage.EmailSent",
          breadcrumbTitle: "login.EmailSent",
        },
      },
      {
        path: "resend-email",
        name: "ResendEmail",
        component: () => import("../views/Auth/resend-email/ResendConfirmation.vue"),
        meta: {
          breadcrumb: [
            { text: "header.Home", to: "/", disabled: false },

            { text: "header.Login", to: "/login", disabled: false },
            { text: "login.ResendEmail", to: "/", disabled: true },
          ],
          breadcrumbHeight: "335px",
          breadcrumbTitle: "login.ResendEmail",
          title: "titlePage.ResendEmail",
        },
      },


      // COURSES


      {
        path: "courses",
        name: "AllCourses",
        component: () => import("../views/courses/index.vue"),


        meta: {
          breadcrumb: [
            { text: "header.Home", to: "/", disabled: false },

            { text: "header.AllCourses", to: "/", disabled: true },
          ],
          breadcrumbHeight: "auto",
          breadcrumbTitle: "header.AllCourses",
          title: "titlePage.AllCourses",
        },


      },
      //ٍsingle COURSES
      {
        path: "course/:id",
        name: "SingleCourse",
        component: () => import("../views/courses/single-course/SingleCourse.vue"),
        meta: {
          breadcrumbHeight: "100",
          customBreadcrumb: true,
        },


      },

      // LECTURE
      {
        path: "lecture/:id",
        name: "Lecture",
        component: () => import("../views/courses/lectures/Lecture.vue"),
        meta: {
          breadcrumbHeight: "0",
          customBreadcrumb: true,
        },
      },
      // {
      //   path: "exam",
      //   name: "Exam",
      //   component: () => import("../views/courses/lectures/components/exam/exam.vue"),
      //   meta: {
      //     customBreadcrumb: true,
      //     title: "titlePage.MyProfile",
      //   },
      // },
      {
        path: "exam/:id",
        name: "Exam",
        component: () => import("../views/courses/lectures/components/exam/exam.vue"),
        meta: {
          customBreadcrumb: true,
          title: "titlePage.MyProfile",
          requiresAuth: true
        },
      },
      // CONTACT US
      {
        path: "contactus",
        name: "Contact Us",
        component: () => import("../views/global/contactus/contactus.vue"),
        meta: {
          breadcrumb: [
            { text: "header.Home", to: "/", disabled: false },

            { text: "header.ContactUs", to: "/", disabled: true },
          ],
          breadcrumbHeight: "335px",
          breadcrumbTitle: "header.ContactUs",
          title: "titlePage.ContactUs",
        },
      },
      // profile MODULE
      {
        path: "/profile",
        component: () => import("../layouts/profile/Profile.vue"),
        children: [
          {
            path: "student-info",
            name: "Info",
            component: () => import("../views/student-profile/student-info/student-info.vue"),
            meta: {
              breadcrumb: [
                { text: "header.Home", to: "/", disabled: false },
                { text: "signUp.MyProfile", to: "/", disabled: true },
              ],
              breadcrumbHeight: "280px",
              breadcrumbTitle: "account.MyProfile",
              title: "titlePage.MyProfile",
              requiresAuth: true 
            },
          },
          {
            path: "password",
            name: "Password",
            component: () => import("../views/student-profile/student-password/student-password.vue"),
            meta: {
              breadcrumb: [
                { text: "header.Home", to: "/", disabled: false },
                { text: "account.Password", to: "/", disabled: true },
              ],
              breadcrumbHeight: "280px",
              breadcrumbTitle: "account.Password",
              title: "titlePage.Password",
              requiresAuth: true 
            },
          },
          {
            path: "payments",
            name: "Payment",
            component: () => import("../views/student-profile/payment/payment.vue"),
            meta: {
              breadcrumb: [
                { text: "header.Home", to: "/", disabled: false },
                { text: "account.Payments", to: "/", disabled: true },
              ],
              breadcrumbHeight: "280px",
              breadcrumbTitle: "account.Payments",
              title: "titlePage.Payments",
              requiresAuth: true 
            },
          },
          {
            path: "subscriptions",
            name: "Subscriptions",
            component: () => import("../views/student-profile/subscriptions/subscriptions.vue"),
            meta: {
              breadcrumb: [
                { text: "header.Home", to: "/", disabled: false },
                { text: "account.Subscriptions", to: "/", disabled: true },
              ],
              breadcrumbHeight: "280px",
              breadcrumbTitle: "account.Subscriptions",
              title: "titlePage.Subscriptions",
              requiresAuth: true 
            },
          },
        ]
      },
      {
        path: "teachers",
        name: "Teachers",
        component: () => import("../views/teacher/index.vue"),


        meta: {
          breadcrumb: [
            { text: "header.Home", to: "/", disabled: false },

            { text: "header.Teachers", to: "/", disabled: true },
          ],
          breadcrumbHeight: "auto",
          breadcrumbTitle: "header.Teachers",
          title: "titlePage.Teachers",
        },

      },
      // become a teacher
      {
        path: "teacher/register",
        name: "Become a Teacher",
        component: () => import("../views/teacher/become-teacher/become-teacher.vue"),
        meta: {
          breadcrumb: [
            { text: "header.Home", to: "/", disabled: false },

            { text: "footer.BecomeaTeacher", to: "/", disabled: true },
          ],
          breadcrumbHeight: "335px",
          breadcrumbTitle: "footer.BecomeaTeacher",
          title: "titlePage.BecomeaTeacher",
        },
      },
      { 
        path: "*", component: () => import("../views/global/not-found/not-found.vue"),
        meta: {
          breadcrumbHeight: 0 ,
          title: "titlePage.NotFound"
        }, 
      }
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,

  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});
router.afterEach((to, from, next) => {
  setTimeout(() => {
    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.title);
    if (nearestWithTitle) document.title = i18n.t(nearestWithTitle.meta.title);
  });
});

const token = localStorage.getItem("7asety_token"); 

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next({
        path: '/login',
        // query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() 
  } 
})


export default router;
