
export default {
  LOGOUT(state) {
    state.status = "";
    state.token = "";
    state.user = "";
},
  SET_TOKEN(state, token) {
    state.token=token
  },
  SET_USER(state, user) {
    state.user=user
  },

}
