<template>
<v-container>
    <v-row>
          <v-col  md="3" cols="12">
              <v-tabs v-model="tab" vertical >
                  <v-tabs-slider class="align-start"></v-tabs-slider>

                  <v-tab v-for="(item, i) in  contents" :key="i" :disabled='activeContent(i, item)'>
                  <v-icon left>{{checkTypeOfContent(item.type)}}</v-icon>
                     {{ item[$i18n.locale].name }}
                  </v-tab>
            </v-tabs>

          </v-col>

          <v-col md="9" cols="12">
             <v-tabs-items v-model="tab" >
                <v-tab-item v-for="(content, i) in  contents" :key="i">
                   <ExamDetails v-if="content.type =='exam'" :content="content"/>
                   <Video v-if="content.type =='video'" :data="content" />
                   <Files v-if="content.type =='file'" :data="content"/>
                </v-tab-item>
             </v-tabs-items>

          </v-col>
       </v-row>
</v-container>
</template>

<script>
import lectures from "../../../../../services/lectures";
import Exam from "../exam/exam.vue";
import Files from "../files/files.vue";
import Video from "../video/video.vue";
import ExamDetails from "../exam/components/exam-details.vue";

export default {
   props : ["lecture"],
   data() {
    return {
        contents: [],
        tab: null,
        exam: null
    }
   },
   components: {
        Video,
        Files,
        ExamDetails
   },
   created() {
       this.getContents(this.lecture.id)
   },
   methods: {
        getContents(id){
                lectures
                .getLectureContents(id)
                .then((resp) => {
                    this.contents = resp.response.data;
                  this.exam = this.contents.find(o => o.type === 'exam');
                })
                .catch((resp) => {
                
                });
        },
        checkTypeOfContent(type) {
            if (type == "file") {
                return "mdi-clipboard-file-outline";
            } else if (type == "video") {
                return "mdi-file-video-outline";
            } else {
                return "mdi-file-clock-outline";
            }
        },
        activeContent(i, item){
           if(i == 0 && item.type == 'exam'){
              return false
           }
           if(i > 0 && this.exam != null){
               let statuses = [ 'success', 'pending'];
               return  !statuses.includes(this.exam.exam.status);
           }

           return false;
        }
   
   }
   
}
</script>