import endpoints from "../common/endpoints";
import http from "../common/http";
const gradesUrl = endpoints.GRADES_ENDPOINT;
const coursesUrl = endpoints.COURSES_ENDPOINT;
const subjectsUrl = endpoints.SUBJECTS_ENDPOINT;
const teachersUrl = endpoints.TEACHERS_ENDPOINT;

export default {
  getGrades(data) {
    return http.get(gradesUrl, data).then((res) => {
      return res;
    });
  },
  getTeachers(data) {
    return http.get(teachersUrl, data).then((res) => {
      return res;
    });
  },
  getSubjects(data) {
    return http.get(subjectsUrl, data).then((res) => {
      return res;
    });
  },
  getCourses(data) {
    return http
      .get(coursesUrl, {
        params: {
          page: data.page,
          teacher_id: data.teacher_id,
          subject_id: data.subject_id,
          grade_id: data.grade_id,
          search: data.search,
          sorting: data.sorting,
        },
      })
      .then((res) => {
        return res;
      });
  },
  getCourseByID(id) {
    return http
      .get(`${coursesUrl}/${id}`)
      .then((res) => {
        return res;
      });
  },
};
