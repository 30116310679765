<template>
  <v-container fill-height="fill-height">
    <v-layout align-center="align-center" justify-center="justify-center">
      <v-flex class="login-form text-xs-center">
        <v-card light="light" max-width="500" elevation="0" class="mx-auto " :class="{'my-12':$vuetify.breakpoint.mdAndUp}">
        
          <v-card-text>
            <validation-observer ref="observer" v-slot="{ invalid }">
              <form @submit.prevent="submit">
                <validation-provider v-slot="{ errors }" name="email" rules="required|email">
                  <v-alert v-if="invalidEmail" dense outlined type="error">{{invalidEmail}}</v-alert>
                  <v-alert dense outlined type="success" v-if="verfied">{{verfied}}</v-alert>
                  <v-text-field
                    v-model="email"
                    :error-messages="errors"
                    :label="$t('validations.fields.email')" 
                    type="email"
                    required
                    single-line
                    outlined
                    height="50"
                  ></v-text-field>
                </validation-provider>

                <v-btn
                  large
                  block
                  :loading="loading"
                  color="primary"
                  
                  type="submit"
                  :disabled="invalid"
                >{{$t('validations.fields.Submit')}}</v-btn>
              </form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    email: "",
    password: "",
    invalidEmail: false,
    rememberMe: false,
    loading: false,
    verfied: "",
  }),

  mounted() {
  },
  methods: {
    submit() {
      // this.$refs.observer.validate();
      this.loading = true;
      const email = this.email;

      this.$store
        .dispatch("auth/forgetPassword", {
          email,
        })
        .then((res) => {
          this.verfied = res.message;
          this.invalidEmail = false;
          this.loading = false;
          localStorage.setItem("7asety_email", email);
         
        })
        .catch((err) => {
          this.verfied = false;
          if (err.errors && err.errors.email) {
            this.invalidEmail = err.errors.email;
          }
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.auth-title {
  font-size: 25px !important;
  font-weight: bold !important;
}
</style>