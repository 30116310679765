import Vue from "vue";
import router from "@/router";
import auth from "../../../services/auth";

const sucessLoginSignUp = (commit, loginUser, token) => {
  const bearerToken = "Bearer " + token;

  Vue.prototype.$http.defaults.headers.common["Authorization"] = bearerToken;
  commit("SET_USER", loginUser);

  commit("SET_TOKEN", token);
};
export default {
  signIn({ commit }, userData) {
    return new Promise((resolve, reject) => {
      auth
        .login(userData.email, userData.password)
        .then((resp) => {
          router.push("/");
          sucessLoginSignUp(
            commit,
            resp.response.data.user,
            resp.response.data.token
          );

          if (userData.rememberMe) {
            localStorage.setItem("7asety_token", resp.response.data.token);
            localStorage.setItem(
              "7asety_user",
              JSON.stringify(resp.response.data.user)
            );
          }

          resolve(resp.response.data);
        })
        .catch((resp) => {
          localStorage.removeItem("7asety_token");
          localStorage.removeItem("7asety_user");
          return reject(resp);
        });
    });
  },
  signUp({ commit }, userData) {
    return new Promise((resolve, reject) => {
      auth
        .signup(userData)

        .then((resp) => {

          sucessLoginSignUp(
            commit,
            resp.response.data.user,
            resp.response.data.token
          );
          localStorage.setItem("7asety_token", resp.response.data.token);
          localStorage.setItem(
            "7asety_user",
            JSON.stringify(resp.response.data.user)
          );
          resolve(resp.response.data);
        })
        .catch((resp) => {
          localStorage.removeItem("7asety_token");
          localStorage.removeItem("7asety_user");
          return reject(resp);
        });
    });
  },
  forgetPassword({ commit }, userData) {
    return new Promise((resolve, reject) => {
      auth
        .forgetPassword(userData)
        .then((resp) => {
          router.push("/login");

          resolve(resp.response);
        })
        .catch((resp) => {
          return reject(resp);
        });
    });
  },
  resetPassword({ commit }, userData) {
    return new Promise((resolve, reject) => {
      auth
        .resetPassword(userData)
        .then((resp) => {
          router.push("/login");
          resolve(resp.response);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  resendEmail({ commit }, data) {
    return new Promise((resolve, reject) => {
      auth
        .resendEmail(data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  checkEmail({ commit }, data) {
    return new Promise((resolve, reject) => {
      auth
        .checkEmail(data)
        .then((res) => {
          resolve(res.response);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  logout({ commit }) {
    return new Promise((resolve) => {
      commit("LOGOUT");
      router.push("/");
      localStorage.removeItem("7asety_token");
      localStorage.removeItem("7asety_user");
      delete Vue.prototype.$http.defaults.headers.common["Authorization"];
      resolve();
    });
  },
};
