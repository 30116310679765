export default {
    SET_EXAMS(state, exams) {
      state.exams = exams;
    },
    SHOW_EXAMS(state,showExams){
      state.showExams = showExams;

    }
  

  };