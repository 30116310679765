<template>
      <div class="breadcrumb breadcrumb__custom" :style="{'height':'auto'}">
      <v-container>
        <v-row no-gutters>
          <v-col md="10" cols="12">
            <v-breadcrumbs :items="breadcrumb">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :to="item.to" :disabled="item.disabled">{{ $t(item.text) }}</v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
            <h3>{{lecture[$i18n.locale].name}}</h3>
            <v-card-text class="pl-0 pt-0">
              <v-row align="center" class="mx-0">
                <v-rating
                  :value="lecture.rate&&lecture.rate.total_rate ?lecture.rate.total_rate  : 0"
                  background-color="orange lighten-3"
                  color="orange"
                  readonly
                  size="20"
                ></v-rating>

                <div class="white--text mx-4">
                  {{lecture.rate&&lecture.rate.total_rate ?lecture.rate.total_rate : 0}}
                  ({{ lecture.rate&&lecture.rate.total_no_rate ?lecture.rate.total_no_rate : 0}} {{$t('courses.Rating')}})
                  <!-- NUMBER OF RATING -->
                </div>
                <v-btn color="black" class="ma-2 white--text">
                  <v-icon left dark>mdi-share-variant-outline</v-icon>
                  {{$t('courses.Share')}}
                </v-btn>
              </v-row>

              <v-row align="center" class="mx-0 mt-0">
                <div
                  :class="$i18n.locale=='en'?'mr-4':'ml-4'"
                  class="my-4 mt-1 text-subtitle-1 white--text"
                >
                  <v-icon color="white">mdi-download-outline</v-icon>
                  {{lecture.no_students ||0}} {{$t('courses.studentsenrolled')}}
                </div>
                <div class="my-4 mt-1 text-subtitle-1 white--text">
                  <v-icon color="white">mdi-comment-account-outline</v-icon>
                  {{lecture.total_no_comments || 0}} {{$t('courses.Review')}}
                </div>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
export default {
   props: ['lecture'],
   data() {
      return {
         breadcrumb: [
            { text: "header.Home", to: "/home", disabled: false },
            { text: "header.AllCourses", to: "/courses", disabled: false },
         ],
      }
   },
   created() {
   }
}
</script>