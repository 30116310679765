<template>
  <div>
    <v-card class="mt-14 mb-3" outlined>
      <v-card width="70%" elevation="0">
        <v-row no-gutters align="center" class="mx-0">
          <v-card-title>Add Reviews & Rate</v-card-title>

          <div class="text-center">
            <!-- <v-rating v-model="rate" length="3">
              <template v-slot:item="props">
                <v-icon
                  :color="
                    props.isFilled ? genColor(props.index) : 'grey lighten-1'
                  "
                  style="font-size: 25px"
                  @click="props.click"
                >
                  {{ genIcon(props.index) }}
                </v-icon>
              </template>
            </v-rating> -->
         
              <v-tabs v-model="rate" hide-slider>
            <v-tab class="pa-0 ma-0" style="min-width:0px" />
            <v-tab class="red--text" style="min-width:30px">
              <v-icon size="32">mdi-emoticon-sad-outline</v-icon>
            </v-tab>
            <v-tab class="orange--text" style="min-width:30px">
              <v-icon size="32">mdi-emoticon-neutral-outline</v-icon>
            </v-tab>
            <v-tab class="green--text" style="min-width:30px">
              <v-icon size="32">mdi-emoticon-happy-outline</v-icon>
            </v-tab>
          </v-tabs>
         
          </div>

          <v-col cols="12">
            <v-card-text class="py-1">
              <p class="greyish_brown--text">What is it like to Course?</p>
            </v-card-text>
          </v-col>
          <v-col cols="12">
            <v-card-text class="pt-0">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <form @submit.prevent="createReview">
                  <validation-provider
                    v-slot="{ errors }"
                    name="reviewcontnent"
                    rules="min:2"
                  >
                    <label class="greyish_brown--text">Comment</label>
                    <v-textarea
                      v-model="reviewContnent"
                      type="text"
                      :error-messages="errors"
                      single-line
                      outlined
                    ></v-textarea>
                  </validation-provider>

                  <v-btn
                    large
                    color="primary"
                    type="submit"
                    :loading="loader"
                    :disabled="invalid || !rate"
                  >
                    Submit Review
                    <v-icon right dark>mdi-arrow-right</v-icon>
                  </v-btn>
                </form>
              </validation-observer>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-card>

    <v-list two-line>
      <v-toolbar-title>Reviews</v-toolbar-title>
      <v-list-item-subtitle
        v-if="!reviews.length"
        class="warm_gray--text text-center"
        >{{ $t("courses.CourseNotFound") }}</v-list-item-subtitle
      >
      <template v-for="item in reviews">
        <v-list-item :ripple="false" :key="item.title">
          <template>
            <v-list-item-avatar class="mb-auto">
              <v-avatar size="40" color="black">
                <span class="white--text">{{
                  item.user_name | short_hand
                }}</span>
              </v-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="item.user_name"></v-list-item-title>

              <!-- <v-list-item-subtitle
                  class="text--primary"
                  v-text="item.headline"
              ></v-list-item-subtitle>-->
              <v-list-item-action-text>{{
                item.date | formateTime
              }}</v-list-item-action-text>

              <v-list-item-subtitle
                class="mt-3"
                style="white-space: break-spaces"
                v-text="item.comment"
              ></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <!-- <v-rating
                :value="item.rate || 0"
                length="3"
                class="emoji-reviews"
              >
                <template v-slot:item="props">
                  <v-icon
                    :color="
                      props.isFilled ? genColor(props.index) : 'grey lighten-1'
                    "
                    style="font-size: 25px"
                  >
                    {{ genIcon(props.index) }}
                  </v-icon>
                </template>
              </v-rating> -->
             <v-tabs hide-slider :value="item.rate || 0" >
            <v-tab class="pa-0 ma-0" style="min-width:0px" />
            <v-tab class="red--text pa-2" style="pointer-events:none; min-width:10px">
              <v-icon size="27">mdi-emoticon-sad-outline</v-icon>
            </v-tab>
            <v-tab class="orange--text pa-2" style="pointer-events:none; min-width:10px">
              <v-icon size="27">mdi-emoticon-neutral-outline</v-icon>
            </v-tab>
            <v-tab class="green--text pa-2" style="pointer-events:none; min-width:10px">
              <v-icon size="27">mdi-emoticon-happy-outline</v-icon>
            </v-tab>
          </v-tabs>
            </v-list-item-action>
          </template>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
import lecturesModule from "@/store/modules/lectures";

export default {
 
  data: () => ({
    reviewContnent: null,
    rate: null,
    colors: ["red", "orange", "green"],
    icons: [
      "mdi-emoticon-sad-outline",
      "mdi-emoticon-neutral-outline",
      "mdi-emoticon-happy-outline",
    ],
    loader: false,
    lectureId: null,
    reviews: [],
  }),

  computed: {
    //  reviews() {
    //    return this.$store.state.lectures.reviews;
    //  },
  },
  created() {
   if (!lecturesModule.isRegistered) {
      this.$store.registerModule("lectures", lecturesModule);
      lecturesModule.isRegistered = true;
    }
   //  console.log(this.lectureId, "in review");
   this.lectureId = this.$route.params.id;
    this.getReviews(this.lectureId);
  },
  filters: {
    short_hand(company_name) {
      // You can put your logic here...
      let words = company_name.split(" ");
      let short_hand = words[0][0].toUpperCase();
      return short_hand; // <-- The return value as Per logic
    },
  },
  methods: {
    genColor(i) {
      return this.colors[i];
    },
    genIcon(i) {
      return this.icons[i];
    },
    createReview() {
      this.loader = true;

      const body = {
        id: this.$route.params.id,
        model: "lecture",
        body: {
          rate: this.rate,
          comment: this.reviewContnent,
        },
      };
      this.$store
        .dispatch("lectures/createReview", body)
        .then(() => {
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getReviews(lectureId) {
      this.$store
        .dispatch("lectures/reviews", lectureId)
        .then((res) => {
           this.reviews = res;
        })
        .catch((err) => {
          console.log(err)
        });
    },
  },
};
</script>
<style scoped>

.emoji {
  margin-top: -10px;
}
.emoji-reviews {
  margin-top: -38px;
}
</style>