
<template>
    <div class="hero" :style="{'height':'630px'}">
      <v-container>
        <h2>{{$t('hero.header')}}</h2>
        <p>{{$t('hero.title')}}</p>
        <v-btn class="ma-2" large outlined rounded color="white" @click="scroll">{{$t('Buttons.ReadytogetStarted')}}</v-btn>
      
      </v-container>
    </div>
</template>

<script>
export default {
  methods: {
     scroll() {
      const element = document.getElementById('scroll-down');
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
};
</script>

<style>
</style>