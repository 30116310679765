<template>
  <v-container fill-height="fill-height">
    <v-layout class="courses">
      <v-navigation-drawer v-model="drawer" app temporary bottom right v-if="!$vuetify.breakpoint.mdAndUp">
              <v-list subheader flat class="pt-2">
          <p class="mb-0 overflow-auto">
            <b style="vertical-align: sub;">{{$t('courses.Grade')}}</b>
                       <v-btn
              @click="resetFilters"
              :class="$i18n.locale=='en'?'float-right':'float-left'"
              text
              color="error"
            >
               {{$t('courses.ResetFilters')}}
            </v-btn>
          </p>

          <v-list-item-group>
            <v-list-item
              :ripple="false"
              :class="$i18n.locale=='en'?'pl-0':'pr-0'"
              :key="index+'grade'"
              v-for="(grade, index) of grades"
            >
              <template>
                <v-list-item-action class="mr-1">
                  <v-checkbox v-model="grade_id" :value="grade.id" color="primary"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-subtitle>{{grade[$i18n.locale].name}}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-divider></v-divider>
        <v-list subheader flat class="pt-2">
          <p class="mb-0">
            <b>{{$t('courses.Subject')}}</b>
          </p>

          <v-list-item-group>
            <v-list-item
              :ripple="false"
              :class="$i18n.locale=='en'?'pl-0':'pr-0'"
              :key="index+'subject'"
              v-for="(subject, index) of subjects"
            >
              <template>
                <v-list-item-action class="mr-1">
                  <v-checkbox v-model="subject_id" :value="subject.id" color="primary"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-subtitle>{{subject[$i18n.locale].name}}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-row :class="{'my-12':$vuetify.breakpoint.mdAndUp}">
        <v-col lg="9" cols="12" :order="$vuetify.breakpoint.mdAndDown?'last':'first'">
          <div class="courses__search-list">
            <v-row class="pt-5" :no-gutters="$vuetify.breakpoint.smAndDown">
              <v-col md="4" cols="6" :class="{'my-auto':$vuetify.breakpoint.mdAndDown}" >
                <span :class="$i18n.locale=='en'?'mr-5':'ml-5'">
                  <span class="total-courses">{{teachersMeta.total}}</span>
                  {{$t('teachers.teacher')}}
                </span>
              </v-col>
              <v-col   v-if="!$vuetify.breakpoint.mdAndUp" cols="6" class="mb-3" :class="$i18n.locale=='en'?'text-right':'text-left'">
                <v-btn
               
                  fab
                  elevation="0"
                  @click.stop="drawer = !drawer"
                  color="error"
                >
                  <v-icon color="white">mdi-filter-menu</v-icon>
                </v-btn>
              </v-col>
             
              <v-col md="4" cols="12" :class="$i18n.locale=='en'?'ml-auto':'mr-auto'">
                <v-text-field
                  :label="$t('teachers.SearchOurTeachers')"
                 
                  solo
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div class="loader" v-if="loader=='loadingteacher' ||loader=='noteachers'||loader=='error'">
            <v-progress-circular
              v-if="loader=='loadingteacher'"
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
            <v-list-item-subtitle
              v-if="loader=='noteachers'"
              class="warm_gray--text text-center"
            >{{$t('teachers.teacherNotFound')}}</v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="loader=='error'"
              class="danger--text text-center"
            >{{$t('courses.ErrorOccured')}}</v-list-item-subtitle>
          </div>
           <!-- CARDS -->
          <template>
            <v-row >
            <v-col
              md="4"
              sm="5"
              cols="12"
              v-show="loader=='success' "
              :key="index+'teacher'"
               v-for="(teacher, index) of teachers"
            >
             <!-- :to="`course/${teacher.id}`" -->
            <v-card
                class="mx-auto my-12"
                max-width="300"
                :to="'courses?page=1&teacher='+teacher.id"
             > 
                <div class="mx-auto text-center mb-4 pt-10">
                  <v-avatar
                    size="150"
                  >
                    <img
                      height="250"
                      :src="teacher.image"
                      :lazy-src="teacher.image"
                    >
                  </v-avatar>
                </div>
                <v-card-title primary-title class="justify-center">{{teacher[$i18n.locale].name}}</v-card-title>

                <v-card-text >
                  <div class="mb-4 text-subtitle-1 text-center">
                       {{teacher[$i18n.locale].description}}
                  </div>

          <v-row
        align="center"
        class="mx-0 justify-center"
      >
        <v-rating
          :value="teacher.rate || 0"
          color="orange"
          background-color="orange lighten-3"
          dense
          half-increments
          readonly
          size="20"
        ></v-rating>

        <div class="grey--text ms-4">
          <!-- ({{teacher.rate || '0'}}) -->
        </div>
      </v-row>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-row
    class="mt-4 pb-4"
     justify= "space-around"
    >
      <div class="grey--text ">
          {{teacher.no_enrollments}} {{$t("teachers.Enrollments")}} 
      </div>
      <div class="grey--text ">
          {{teacher.number_of_courses}} {{$t("teachers.courses")}}
      </div>
    </v-row>

             </v-card>
            </v-col>
            </v-row>
          </template>
        </v-col>
        <v-col lg="3" cols="12" v-if="$vuetify.breakpoint.mdAndUp">
             <v-list subheader flat>
            <p class="mb-0 overflow-auto">
              <b style="vertical-align: sub;">{{$t('courses.Grade')}}</b>
              <v-btn   :class="$i18n.locale=='en'?'float-right':'float-left'" @click="resetFilters" class="float-"  text color="error">
               {{$t('courses.ResetFilters')}}
              </v-btn>
            </p>

            <v-list-item-group>
              <v-list-item
                :ripple="false"
                :class="$i18n.locale=='en'?'pl-0':'pr-0'"
                :key="index+'grade'"
                v-for="(grade, index) of grades"
              >
                <template>
                  <v-list-item-action class="mr-1">
                    <v-checkbox v-model="grade_id" :value="grade.id" color="primary"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{grade[$i18n.locale].name}}
                      <span
                        :class="$i18n.locale=='en'?'float-right':'float-left'"
                      >({{grade.number_of_teachers}})</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-list subheader flat>
            <p class="mb-0">
              <b>{{$t('courses.Subject')}}</b>
            </p>

            <v-list-item-group>
              <v-list-item
                :ripple="false"
                :class="$i18n.locale=='en'?'pl-0':'pr-0'"
                :key="index+'subject'"
                v-for="(subject, index) of subjects"
              >
                <template>
                  <v-list-item-action class="mr-1">
                    <v-checkbox v-model="subject_id" :value="subject.id" color="primary"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{subject[$i18n.locale].name}}
                      <span
                        :class="$i18n.locale=='en'?'float-right':'float-left'"
                      >({{subject.number_of_teachers}})</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>

        </v-col>
        <v-col   v-if="teachersMeta.last_page >1" cols="12" order="last">
          <v-pagination
            class="my-5"
          
            :length="teachersMeta.last_page"
            v-model="page"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import coursesModule from "@/store/modules/courses";
import teachersModule from '@/store/modules/teachers';

export default {
  data: () => ({
    page: 1,
    subject_id: null,
    grade_id: null,
    search: "",
    loader: null,
    drawer: false,
  }),
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      // this.fab = top > 20;
    },
    getGrades() {
      this.loader = "loading";
      this.$store
        .dispatch("courses/getGrades")
        .then(() => {
          this.loader = "success";
        })
        .catch(() => {
          this.loader = "error";
        });
    },
    getTeachers() {
      this.loader = "loading";
      this.$store
        .dispatch("teachers/getTeachers")
        .then(() => {
          this.loader = "success";
        })
        .catch(() => {
          this.loader = "error";
        });
    },
    getSubjects() {
      this.loader = "loading";
      this.$store
        .dispatch("courses/getSubjects")
        .then(() => {
          this.loader = "success";
        })
        .catch(() => {
          this.loader = "error";
        });
    },
    getTeachers(query) {
      this.page = +query.page;
      this.$router.push({
        query: Object.assign({}, this.$route.query, { page: query.page }),
      });

      const params = {
        page: query.page,
        subject_id: query.subject,
        grade_id: query.grade,
        search: query.search,
      };

      this.loader = "loadingteacher";
      this.$store
        .dispatch("teachers/getTeachers", params)
        .then((res) => {
          if (res.length) {
           
            this.loader = "success";
          } else {
          
            this.loader = "noteachers";
          }
        })
        .catch(() => {
          this.loader = "error";
        });
    },
    resetFilters() {
      this.$router.push({
        query: { page: 1 },
      });
      this.grade_id = ''
      this.subject_id = ''
    },
  },
  watch: {
    $route(val) {
      this.getTeachers(val.query)
    },
    page(obj) {
      this.page = +obj || 1;
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          page: this.page,
        }),
      });
    },
    grade_id(obj) {
      let query = Object.assign({}, this.$route.query);

      delete query.grade;
      if (obj) {
        this.$router.push({
          query: Object.assign({}, this.$route.query, {
            grade: this.grade_id,
          }),
        });
      } else {
        this.$router.replace({ query });
      }
    },
    subject_id(obj) {
      let query = Object.assign({}, this.$route.query);
      this.subject_id = obj;

      delete query.subject;
      if (obj) {
        this.$router.push({
          query: Object.assign({}, this.$route.query, {
            subject: this.subject_id,
          }),
        });
      } else {
        this.$router.replace({ query });
      }
    },
      search(val) {
      let query = Object.assign({}, this.$route.query);

      delete query.search;
      if (val) {
        this.$router.push({
          query: Object.assign({}, this.$route.query, {
            search: val,
          }),
        });
      } else {
        this.$router.replace({ query });
      }
    },
    teacher_id(obj) {
      let query = Object.assign({}, this.$route.query);

      delete query.teacher;
      if (obj) {
        this.$router.push({
          query: Object.assign({}, this.$route.query, {
            teacher: this.teacher_id,
          }),
        });
      } else {
        this.$router.replace({ query });
      }
    },
  },

  computed: {
    grades() {
      return this.$store.state.courses.grades;
    },
    subjects() {
      return this.$store.state.courses.subjects;
    },
    teachers() {
      return this.$store.state.teachers.teachers;
    },
     teachersMeta() {
      return this.$store.state.teachers.teachersMeta;
    },
  },
  created() {
    if (!coursesModule.isRegistered) {
      this.$store.registerModule("courses", coursesModule);
      coursesModule.isRegistered = true;
    }
    if (!teachersModule.isRegistered) {
      this.$store.registerModule("teachers", teachersModule);
      teachersModule.isRegistered = true;
    }
    this.getGrades();
    this.getSubjects();
    if (this.$route.query) {
      this.getTeachers(this.$route.query);
    }
 
  },
};
</script>

<style>
</style>